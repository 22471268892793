import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { UniCreateStepValues } from 'src/types/lakehouse'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import notify from 'src/utils/notify'

type LakehouseClouderaConnectionInput = {
  jdbcUrl: string
  atlasUrl: string
  username: string
  password: string
  database: string
}

export const StepConnectCloudera: StepComponent<UniCreateStepValues> = (props) => {
  const { stepValues, setCurrStepIndex, setStepValues, currStepIndex, submitStep, form } = props
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()
  const getTablesMutation = useMutation({
    mutationFn: (connectionInput: LakehouseClouderaConnectionInput) => Promise.resolve('TODO: implement this'),
    onError: (error) => notify.error(`Error testing Snowflake connection: ${error}`),
    onSuccess: (response) => {
      setStepValues({
        ...stepValues,

        //Not sure if it's possible to get the region, so defaulting to us-east-1
        sourceRegion: 'us-east-1',

        //hardcoded for now until we have a query to get the available tables
        availableTables: ['investments'],
      })
      setCurrStepIndex(currStepIndex + 1)
    },
  })
  const {} = props

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Connect to Cloudera'
        description={'Let’s authenticate your Cloudera account'}
      />
      <ScrollableStepContent verticallyCentered>
        <div className='w-full max-w-4xl'>
          <div className='flex w-full flex-col gap-5'>
            <TextField
              label='JDBC URL'
              description='The Hive JDBC URL for your Cloudera CDP warehouse'
              name='clouderaJdbcUrl'
              type='text'
              form={form}
              validators={isRequiredOnBlur}
              useNestedLabel
            />
            <TextField
              label='Atlas URL'
              description='The Atlas URL for your Cloudera CDP warehouse'
              name='clouderaAtlasUrl'
              type='text'
              form={form}
              validators={isRequiredOnBlur}
              useNestedLabel
            />
            <TextField
              label='Username'
              description='The username for the Cloudera account you want to connect with'
              name='clouderaUsername'
              type='text'
              form={form}
              validators={isRequiredOnBlur}
              useNestedLabel
            />
            <TextField
              label='Password'
              description='The password for the Cloudera account you want to connect with'
              name='clouderaPassword'
              type='password'
              form={form}
              validators={isRequiredOnBlur}
              useNestedLabel
            />
            <TextField
              label='Database'
              description='The database containing the tables you want to share'
              name='clouderaDatabase'
              type='text'
              form={form}
              validators={isRequiredOnBlur}
              useNestedLabel
            />
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          kind='secondary'
          className='w-28'
          // onClick={() => setSearchParams({ flow: CreateFlow.SELECT_TYPE })}
          onClick={() => submitStep({ nextStepIndex: currStepIndex - 1 })}
          data-testid='back-button'
        >
          Back
        </Button>
        <Button
          kind='primary'
          className='w-28'
          icon={getTablesMutation.isPending ? 'refresh' : null}
          iconProps={{
            isSpinning: getTablesMutation.isPending,
          }}
          disabled={getTablesMutation.isPending}
          onClick={async () => {
            // Stay on same step, validate form
            const latestStepValues = await submitStep({ nextStepIndex: currStepIndex })
            if (latestStepValues !== null) {
              const connectionInput: LakehouseClouderaConnectionInput = {
                jdbcUrl: latestStepValues.clouderaJdbcUrl,
                atlasUrl: latestStepValues.clouderaAtlasUrl,
                username: latestStepValues.clouderaUsername,
                password: latestStepValues.clouderaPassword,
                database: latestStepValues.clouderaDatabase,
              }
              getTablesMutation.mutate(connectionInput)
            }
          }}
          data-testid='next-button'
        >
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
