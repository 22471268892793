import {} from '@tanstack/react-form'
import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import debug from 'debug'
import Button from 'src/components/buttons/button'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { EditSharingPermissions } from 'src/components/lakehouse/edit-sharing-permissions'
import { UniCreateStepValues } from 'src/types/lakehouse'
import { getCurrentProduct, getPrettyProductRegions } from 'src/utils/lakehouse/products'

import { StepId } from '../config'

const logger = debug('app:sharingPermissions')

export const StepSharingPermissionsCreate: StepComponent<UniCreateStepValues> = (props) => {
  const { stepValues, submitStep, currStepIndex, form } = props
  const product = getCurrentProduct(stepValues)
  const regions = getPrettyProductRegions(product)

  async function saveSharingPermissions() {
    // Stay on same step, validate form
    const latestStepValues = await submitStep({ nextStepIndex: currStepIndex })
    if (!latestStepValues) {
      return
    }
    submitStep({ nextStepId: StepId.DataProducts })
  }

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Configure sharing permissions'
        description={
          <span className='text-base font-normal'>
            Define how you would like to regulate the data for{' '}
            <span className='text-neutral-9 font-bold'>{product?.name}</span> located in{' '}
            <span className='text-neutral-9 font-bold'>{regions}</span>
          </span>
        }
      />
      <ScrollableStepContent inset>
        <div className='w-full max-w-7xl'>
          <div className='flex w-full flex-col gap-4 px-4'>
            {/* @ts-ignore tricky type problem */}
            <EditSharingPermissions {...props} />
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        {/* <Button
          kind='tertiary'
          className='w-28'
          // TODO: fis this - need to copy the data and discard the changes if they cancel edits
          onClick={() => setCurrStepId(StepId.EditDataProducts)}
          data-testid='back-button'
        >
          Cancel
        </Button> */}
        <form.Subscribe
          selector={(state) => [
            state.isDirty,
            state.isFormValid,
            state.values.products[stepValues.sharingPermissionsEditIndex],
          ]}
        >
          {([isDirty, isFormValid, currentProductValues]) => (
            <Button
              kind='primary'
              disabled={
                (currentProductValues?.defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude &&
                  currentProductValues?.columnPolicies.length === 0) ||
                (isDirty && !isFormValid)
              }
              onClick={saveSharingPermissions}
              data-testid='next-button'
            >
              Save Sharing Permissions
            </Button>
          )}
        </form.Subscribe>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
