import { useSearchParams } from 'react-router-dom'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { UniCreateStepValues } from 'src/types/lakehouse'
import useFeatureToggle, { Features } from 'src/utils/hooks/use-feature-toggle'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'
import { generateShortId } from 'src/utils/short-id'

import { CreateFlow, StepId } from './config'
import { StepChooseSource } from './flow-lakehouse/choose-source.step'
import { StepConnectCloudera } from './flow-lakehouse/connect-cloudera'
import { StepConnectSnowflake } from './flow-lakehouse/connect-snowflake'
import { CreateDataProductsStep } from './flow-lakehouse/create-data-products.step'
import { StepCreateLakehouse } from './flow-lakehouse/create-lakehouse.step'
import { StepSharingPermissionsCreate } from './flow-lakehouse/create-sharing-policies.step'
import { StepSelectTable } from './flow-lakehouse/select-table.step'
import { StepSelectUniType } from './flow-select/select-uni-type.step'
import { StepAddNodes } from './flow-standard/add-nodes.step'
import { StepCreateUni } from './flow-standard/create-uni.step'
import { StepInvitePartners } from './flow-standard/invite-partners.step'
import { StepSchemaDesigner } from './flow-standard/schema-designer.step'
import { StepSchemaEditMode } from './flow-standard/schema-edit-mode.step'

export default function UniCreate() {
  useDocumentTitle(`Create Uni - ${POSTFIX}`)
  const [searchParams, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const selectedFlow = searchParams.get('flow') as CreateFlow
  const isValidFlow = Object.values(CreateFlow).includes(selectedFlow)

  const { isLoading, isEnabled: entitlementsUniFlowEnabled } = useFeatureToggle(Features.DATA_DISTRO)

  if (isLoading) {
    return null
  }

  if (selectedFlow === 'standard' || !entitlementsUniFlowEnabled || !isValidFlow) {
    return (
      <MultiStepFlow
        key='standard'
        initialStepValues={{
          // So the flow will have a stable random uni name suffix we can show them in naming
          // step and use in the final step to generate the uni
          randomSuffix: generateShortId({ existingIds: [] }),
        }}
        stepConfig={[
          {
            id: StepId.CreateNetwork,
            navTitle: 'Create Uni',
            StepComponent: StepCreateUni,
          },
          {
            id: StepId.AddNodes,
            navTitle: 'Add a node',
            StepComponent: StepAddNodes,
          },
          {
            id: StepId.SchemaEditMode,
            navTitle: 'Create data model',
            StepComponent: StepSchemaEditMode,
          },
          {
            id: StepId.SchemaDesigner,
            navTitle: '', // Not displayed
            showInStepNav: false,
            StepComponent: StepSchemaDesigner,
          },
          {
            id: StepId.InvitePartners,
            navTitle: 'Invite partners',
            StepComponent: StepInvitePartners,
          },
        ]}
      />
    )
  }

  // Single step flow for deciding which of the other flows to enter
  if (selectedFlow === 'select-type') {
    return (
      <MultiStepFlow
        key='select-type'
        hideStepNav
        stepConfig={[
          {
            id: StepId.SelectUniType,
            navTitle: 'Select Uni Type',
            StepComponent: StepSelectUniType,
          },
        ]}
      />
    )
  }

  if (selectedFlow === 'entitlements') {
    return (
      <MultiStepFlow<UniCreateStepValues>
        key='entitlements'
        stepConfig={[
          {
            id: StepId.CreateLakehouse,
            StepComponent: StepCreateLakehouse,
          },
          {
            id: StepId.ChooseSource,
            StepComponent: StepChooseSource,
          },
          {
            id: StepId.ConnectSnowflake,
            StepComponent: StepConnectSnowflake,
          },
          {
            id: StepId.ConnectCloudera,
            StepComponent: StepConnectCloudera,
          },
          {
            id: StepId.SelectSnowflakeTable,
            StepComponent: StepSelectTable,
          },
          {
            id: StepId.DataProducts,
            StepComponent: CreateDataProductsStep,
          },
          {
            id: StepId.SharingPermissions,
            StepComponent: StepSharingPermissionsCreate,
            showInStepNav: false,
          },
        ]}
      />
    )
  }

  // Will never happen, rather keep if cases explicit above for clarity
  return null
}

/* WIP, finish after react-router 6.4 upgrade and move to "data APIs" (createBrowerRouter) */
/* AND/OR serialize and save state to localStorage so we can restore where they left off */
/* 
<BasicModal
  title='Are you sure you want to leave?'
  isOpen={blocker.state === 'blocked'}
  onClose={() => {
    blocker.reset()
  }}
  buttons={
    <div className='flex gap-2'>
      <Button kind={'secondary'} onClick={() => blocker.reset()}>
        Cancel
      </Button>
      <Button
        kind={'primary'}
        onClick={() => {
          blocker.proceed()
        }}
      >
        Yes, I'd like to leave
      </Button>
    </div>
  }
>
  <p>You have unsaved changes. Are you sure you want to leave?</p>
</BasicModal> 
*/
