import Auth from '@aws-amplify/auth'
import React, { useEffect } from 'react'
import LoginForm from 'src/components/forms/login-form'
import Illustration from 'src/layouts/illustration.layout'
import { authContext } from 'src/utils/auth/auth-context'
import { getLoggedInNodeAmplifyConfig, getLoggedInNodeFromQueryString } from 'src/utils/auth/logged-in-node'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'

export default function Login() {
  useDocumentTitle(`Log in - ${POSTFIX}`)
  const { signedInNode, unsetSignedInNodeStatus } = React.useContext(authContext)
  let nodeTitle
  if (signedInNode) {
    nodeTitle = ` to ${signedInNode.uniName.replace('.unis.vendia.net', '')} - ${signedInNode.nodeName}`
  }
  const formTitle = <h3 className='mb-[35px] text-xl font-semibold'>Log in to Vendia Share {nodeTitle}</h3>

  useEffect(() => {
    const loggedInNodeQueryString = getLoggedInNodeFromQueryString()
    if (!loggedInNodeQueryString) unsetSignedInNodeStatus()
    const amplifyConfig = getLoggedInNodeAmplifyConfig({ signedInNode })
    Auth.configure(amplifyConfig)
  }, [])

  return (
    <Illustration>
      <LoginForm formTitle={formTitle} />
    </Illustration>
  )
}
