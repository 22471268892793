import { UpdateSnowflakeStepValues } from 'src/types/lakehouse'

import TextField from '../fields/text.field'
import { StepComponent } from '../flows/types'
import ExternalLink from '../navigation/external-links'

export const SnowflakeConnection: StepComponent<UpdateSnowflakeStepValues> = (props) => {
  const { form } = props
  return (
    <div className='w-full max-w-4xl'>
      <div className='flex w-full flex-col gap-4'>
        <TextField
          name='snowflakeHost'
          label='Snowflake Account Identifier'
          description={
            <span>
              The account identifier is the first part of your Snowflake account&apos;s URL, eg.
              &lt;account-identifier&gt;.snowflakecomputing.com.{' '}
              <ExternalLink
                className=''
                iconSize='xxs'
                href='https://docs.snowflake.com/en/user-guide/admin-account-identifier'
              >
                Learn more
              </ExternalLink>
            </span>
          }
          className='!w-max flex-1 !rounded-none !border-l-0 !border-r-0'
          leftOfInput={
            <div className='border-neutral-9 bg-uibg-1 select-none truncate rounded-md rounded-r-none border p-2'>
              https://
            </div>
          }
          rightOfInput={
            <div className='border-neutral-9 bg-uibg-1 select-none truncate rounded-md rounded-l-none border p-2'>
              .snowflakecomputing.com
            </div>
          }
          placeholder='ABC123.us-east-1 or ORG123.ABC123'
          form={form}
          useNestedLabel
        />

        <TextField
          name='snowflakeWarehouse'
          label='Warehouse'
          description='The warehouse that should be used to execute queries'
          form={form}
          useNestedLabel
        />

        <TextField
          name='snowflakeDatabase'
          label='Database'
          description='The database containing the tables you want to share'
          form={form}
          useNestedLabel
        />

        <TextField
          name='snowflakeUsername'
          label='Username'
          description='The username for the Snowflake account you want to connect with'
          form={form}
          useNestedLabel
        />

        <TextField
          name='snowflakePassword'
          label='Password'
          description='The password for the Snowflake account you want to connect with'
          form={form}
          type='password'
          useNestedLabel
        />

        <TextField
          name='snowflakeSchema'
          label='Schema (Optional)'
          description='The schema where your tables are located. "PUBLIC" schema will be used by default'
          type='text'
          form={form}
          useNestedLabel
        />

        <TextField
          name='snowflakeRole'
          label='Role (Optional)'
          description="The name of the role to use when connecting to Snowflake. If not provided, the user's default role will be used."
          form={form}
          useNestedLabel
        />
      </div>
    </div>
  )
}
