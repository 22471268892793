export const AZURE_REGIONS = [
  { value: 'eastus', label: 'Virginia' },
  { value: 'westus2', label: 'Washington' },
  { value: 'northeurope', label: 'Ireland' },
  { value: 'germanywestcentral', label: 'Frankfurt' },
] as const

export const AWS_REGIONS = [
  { value: 'us-west-2', label: 'Oregon', displayAlt: 'US West (Oregon)' },
  { value: 'us-east-1', label: 'N. Virginia', displayAlt: 'US East (N. Virginia)' },
  { value: 'us-east-2', label: 'Ohio', displayAlt: 'US East (Ohio)' },
  { value: 'eu-west-1', label: 'Ireland', displayAlt: 'EU West (Ireland)' },
  { value: 'eu-central-1', label: 'Frankfurt', displayAlt: 'EU Central (Frankfurt)' },
] as const

export const SIMPLE_AWS_REGIONS = [
  { value: 'eu-central-1', label: 'Germany' },
  { value: 'eu-west-1', label: 'Ireland' },
  { value: 'us-west-2', label: 'United States' },
] as const

const AWS_AZURE_MAPPING = [
  ['us-east-1', 'eastus'],
  ['us-west-2', 'westus2'],
  ['eu-central-1', 'germanywestcentral'],
  ['eu-west-1', 'northeurope'],
] as const

export const findAwsRegionForAzureRegion = (azureRegion?: string) =>
  AWS_AZURE_MAPPING.find((mapping) => mapping[1] === azureRegion)?.[0]

export const findAwsRegionName = (awsRegion?: string) => AWS_REGIONS.find((region) => region.value === awsRegion)?.label
