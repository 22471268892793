import { useForm } from '@tanstack/react-form'
import { useContext, useEffect, useRef } from 'react'
import Balancer from 'react-wrap-balancer'
import Button from 'src/components/buttons/button'
import Form from 'src/components/fields/form'
import TextField from 'src/components/fields/text.field'
import TextAreaField from 'src/components/fields/text-area.field'
import BasicModal from 'src/components/modals/modal.basic'

import { logger } from '../flow-standard/schema-designer.step'
import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'
import { getSchemaKeyFromName } from './utils'

const CreateEntityDescription = () => {
  return (
    <>
      <Balancer>
        Create an <strong>entity</strong> for each collection of items you want to work with. Let's start by providing a
        name and description (optional) for this entity.
      </Balancer>
    </>
  )
}

export function EntityModal({ title, isOpen }: { title: string; isOpen: boolean }) {
  const titleRef = useRef<HTMLInputElement>(null)
  const {
    status,
    setDesignerState,
    selectedEntityKey,
    entityKeys,
    selectedEntity,
    upsertEntity,
    removeEntity,
    evolveSchemaMode,
  } = useContext(SchemaDesignerContext) as SchemaDesignerContextType

  type FormValues = {
    title?: string | null
    description?: string | null
  }

  const defaultValues: FormValues = {
    title: null,
    description: null,
  }

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: async ({ value }) => {
      logger('formValues', value)
      upsertEntity({
        title: value.title!,
        description: value.description! ?? undefined /* remove null */,
        previousEntityKey: status === Status.SHOW_ENTITY_MODAL_EDIT ? selectedEntityKey! : undefined,
      })
      form.reset()
      onClose()
    },
  })

  useEffect(() => {
    if (status === Status.SHOW_ENTITY_MODAL_EDIT) {
      form.update({
        defaultValues: {
          title: selectedEntity?.title ?? selectedEntityKey,
          description: selectedEntity?.description ?? null,
        },
      })
      form.reset()
    }
  }, [status, selectedEntityKey, selectedEntity])

  useEffect(() => {
    titleRef.current?.focus()
  }, [])

  const onClose = () => {
    form.update({ defaultValues })
    form.reset()
    setDesignerState({ status: Status.IDLE })
  }

  return (
    <BasicModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        <div className='flex w-full items-center justify-between'>
          <div>
            {!evolveSchemaMode && status === Status.SHOW_ENTITY_MODAL_EDIT && (
              <Button
                key='delete'
                kind='danger'
                onClick={() => {
                  removeEntity(selectedEntityKey!)
                  onClose()
                }}
              >
                Delete Entity
              </Button>
            )}
          </div>
          <div className='flex gap-2'>
            <Button
              key='cancel'
              kind='secondary'
              onClick={() => {
                logger('cancel clicked')
                onClose()
              }}
            >
              Cancel
            </Button>
            <form.Subscribe selector={(state) => state.isFieldsValid}>
              {(isFieldsValid) => (
                <Button key='save' kind='primary' type='submit' form='entity-modal-form' disabled={!isFieldsValid}>
                  Save
                </Button>
              )}
            </form.Subscribe>
          </div>
        </div>
      }
    >
      <Form id='entity-modal-form' className='flex flex-col gap-4' form={form}>
        <CreateEntityDescription />
        <div className='flex flex-col gap-4'>
          <TextField
            ref={titleRef}
            name='title'
            label='Name'
            form={form}
            validators={{
              onChange: ({ value }: any) => {
                if (!value) {
                  return 'This field is required.'
                }
                const valueAsKey = getSchemaKeyFromName({ name: value, capFirstLetter: true })
                if (entityKeys.includes(valueAsKey)) {
                  return 'An entity with this name already exists'
                }
              },
            }}
          />
          <TextAreaField
            name='description'
            label={
              <span>
                Description <span className='font-normal'>(Optional)</span>
              </span>
            }
            placeholder='Description'
            form={form}
            validators={{
              onBlur: ({ value }: { value: string }) => {
                if (value?.length > 200) {
                  return 'Description must be less than 200 characters'
                }
              },
            }}
          />
        </div>
      </Form>
    </BasicModal>
  )
}
