import clsx from 'clsx'
import { useContext } from 'react'
import Icon from 'src/components/icons/icon'

import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'

export const EntityTopNav = () => {
  const { setDesignerState, schema, selectedEntityKey, entityKeys, readOnlyMode } = useContext(
    SchemaDesignerContext,
  ) as SchemaDesignerContextType
  return (
    <div className='border-b-information-9 mb-6 mt-2 flex w-full gap-2 overflow-x-auto border-b-2'>
      {entityKeys.map((entityKey) => {
        const buttonClasses = clsx(
          'flex items-center rounded-2xl rounded-b-none border border-b-0 px-8 py-2 font-bold transition-colors duration-300',
          selectedEntityKey === entityKey && 'bg-information-9 hover:bg-information-8 border-transparent text-white',
          selectedEntityKey !== entityKey &&
            'bg-information-0 hover:bg-information-1 text-information-12 border-information-2 text-opacity-40',
        )
        return (
          <button
            key={entityKey}
            className={buttonClasses}
            onClick={() => {
              setDesignerState({ selectedEntityKey: entityKey })
            }}
          >
            {schema?.properties[entityKey].title || entityKey}
          </button>
        )
      })}
      {readOnlyMode ? null : (
        <button
          onClick={() => {
            setDesignerState({ status: Status.SHOW_ENTITY_MODAL_NEW })
          }}
          className={`border-neutral-6 text-neutral-7 hover:bg-neutral-0 flex items-center whitespace-nowrap rounded-2xl rounded-b-none border border-b-0 border-dashed bg-white px-8 py-2 transition-colors duration-300`}
        >
          <Icon name='plus-m' size={14} className='-ml-0.5 mr-2' />
          Add entity
        </button>
      )}
    </div>
  )
}
