import { useMutation } from '@tanstack/react-query'
import { LakehouseSnowflakeConnectionInput, ShareApp, SnowflakeInfo } from '@vendia/management-api-types'
import debug from 'debug'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { SnowflakeConnection } from 'src/components/lakehouse/snowflake-connection'
import { parsetLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'

import { LakehouseDataLoader } from './lakehouse-data-loader'

const logger = debug('app:PageUpdateSnowflakeConnection')

export interface UpdateSnowflakeStepValues {
  snowflakeHost: string
  snowflakeWarehouse: string
  snowflakeDatabase: string
  snowflakeUsername: string
  snowflakePassword: string
  snowflakeSchema: string
  snowflakeRole: string
}

export const PageUpdateSnowflakeConnection = () => <LakehouseDataLoader Comp={PageUpdateSnowflakeConnectionContent} />

export const PageUpdateSnowflakeConnectionContent = ({ shareApps }: { shareApps: ShareApp[] }) => {
  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parsetLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  // Single source for now & single table for now!
  const sourceConfig = shareAppConfig.sources[0]
  const snowflakeConnection = sourceConfig.snowflakeConnection!

  return (
    // Simple single-step MultiStepFlow for editing connection (keep consistent with create flow)
    <MultiStepFlow<UpdateSnowflakeStepValues>
      initialStepValues={{
        snowflakeHost: snowflakeConnection.account,
        snowflakeWarehouse: snowflakeConnection.warehouse,
        snowflakeDatabase: snowflakeConnection.database,
        snowflakeUsername: snowflakeConnection.username,
        snowflakePassword: '',
        snowflakeSchema: snowflakeConnection.schema,
        snowflakeRole: snowflakeConnection.role,
      }}
      stepConfig={[
        {
          id: 'update-snowflake',
          StepComponent: StepUpdateSnowflake,
        },
      ]}
    />
  )
}

export const StepUpdateSnowflake: StepComponent<UpdateSnowflakeStepValues> = (props) => {
  const { currStepIndex, submitStep } = props
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()
  const api = useApi()

  // TODO: replace this with new resolver to update snowflake connection
  // TODO: - test the connection
  //       - verify that the existing source table is still available
  //       - verify that the source table schema is the same
  //         if the schema has changed, it's possible that polciies may:
  //           - have column policies for columns that don't exist (these could remain and be ignored?. mark them in UI?)
  //           - be missing policies for new columns (with PII, for example) — should warn user they may need to update policies.
  const getTablesMutation = useMutation({
    mutationFn: (connectionInput: LakehouseSnowflakeConnectionInput) =>
      api.getSnowflakeInfo<{
        testLakehouseSnowflakeConnection?: SnowflakeInfo
      }>({ connectionInput }),
    onError: (error) => notify.error(`Error testing Snowflake connection: ${error}`),
    onSuccess: (response) => {
      logger('response', response)
      if (response.message) {
        notify.error(`Error testing Snowflake connection: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.testLakehouseSnowflakeConnection) {
        notify.error('Error testing Snowflake connection')
        return
      }
      navigate('../', { relative: 'path' })
    },
  })

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Update Snowflake connection'
        description={`Update the credentials used to connect to your Snowflake account in case of changes or errors.`}
      />
      <ScrollableStepContent inset verticallyCentered>
        <SnowflakeConnection {...props} />
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('../', { relative: 'path' })} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          kind='primary'
          className='!px-8'
          icon={getTablesMutation.isPending ? 'refresh' : null}
          iconProps={{
            isSpinning: getTablesMutation.isPending,
          }}
          disabled={getTablesMutation.isPending}
          onClick={async () => {
            // Stay on same step, validate form
            const latestStepValues = await submitStep({ nextStepIndex: currStepIndex })
            if (latestStepValues !== null) {
              const {
                snowflakeHost,
                snowflakeWarehouse,
                snowflakeDatabase,
                snowflakeUsername,
                snowflakePassword,
                snowflakeSchema,
                snowflakeRole,
              } = latestStepValues
              const connectionInput = {
                authType: 'PASSWORD',
                account: snowflakeHost,
                username: snowflakeUsername,
                password: snowflakePassword,
                warehouse: snowflakeWarehouse,
                database: snowflakeDatabase,
                schema: snowflakeSchema,
                role: snowflakeRole,
              }
              logger('connectionInput', connectionInput)
              getTablesMutation.mutate(connectionInput)
            }
          }}
          data-testid='next-button'
        >
          Update connection
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
