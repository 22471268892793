import debug from 'debug'
import { useNavigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'

import { CreateFlow } from '../config'

const logger = debug('app:selectUniType')

export const StepSelectUniType: StepComponent = ({
  stepValues,
  setCurrStepIndex,
  setCurrStepId,
  setStepValues,
  currStepIndex,
}) => {
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center justify-center p-4 lg:px-8'>
          <StepContentHeader
            centered
            large
            title='Create your first data set'
            description={'What kind of data set are you trying to configure?'}
          />
          <div className='flex flex-col items-center gap-8 p-16 md:flex-row'>
            <button
              onClick={() => {
                setSearchParams({ flow: CreateFlow.STANDARD })
              }}
            >
              <UniTypeCard
                title='Share data with your partners'
                description='We make it easy to share data with your partners in a real-time, secure way. Share what you want and hide what you don’t.'
                imgSrc='/images/uni-type-standard.png'
                imgAlt='Multi-party data sharing illustration'
              />
            </button>
            <button
              onClick={() => {
                setSearchParams({ flow: CreateFlow.ENTITLEMENTS })
              }}
            >
              <UniTypeCard
                title='Share an existing data set with security'
                description='Data sellers can easily create different sharing permissions for different customers applied to the same data set.'
                imgSrc='/images/uni-type-lakehouse.png'
                imgAlt='Entitlements data sharing illustration'
              />
            </button>
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='secondary' className='w-28' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}

const UniTypeCard = ({
  title,
  description,
  imgSrc,
  imgAlt,
}: {
  title: string
  description: string
  imgSrc: string
  imgAlt: string
}) => {
  return (
    <Card onWhiteBg className='hover:bg-neutral-0 transition-colors'>
      <div className='flex size-72 flex-col gap-3'>
        <div className='flex h-2/3 w-full items-center justify-center'>
          <img src={imgSrc} alt={imgAlt} className='size-36' />
        </div>
        <div className='text-sm font-bold'>{title}</div>
        <div className='text-balance text-xs'>{description}</div>
      </div>
    </Card>
  )
}
