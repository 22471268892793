import debug from 'debug'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { EditDataProducts } from 'src/components/lakehouse/edit-data-products'
import { LakehouseEditStepValues } from 'src/types/lakehouse'

const logger = debug('app:EditDataProducts')

export const StepDataProductsEdit: StepComponent<LakehouseEditStepValues> = (props) => {
  const { setCurrStepIndex, currStepIndex, submitStep } = props
  const navigate = useNavigate()

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Edit data products'
        description={
          'Update your data products and configure sharing permissions. You can also add new data products or remove products that are no longer available.'
        }
      />
      <ScrollableStepContent>
        <div className='w-full max-w-7xl'>
          <div className='flex w-full flex-col gap-4'>
            <EditDataProducts {...props} />
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('../', { relative: 'path' })} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          kind='primary'
          className='w-28'
          onClick={() => submitStep({ nextStepIndex: currStepIndex + 1 })}
          data-testid='next-button'
        >
          Save changes
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
