import { useMutation } from '@tanstack/react-query'
import { SnowflakeTableColumnInput } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import ListboxField from 'src/components/fields/listbox.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { UniCreateStepValues } from 'src/types/lakehouse'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'

import { getEmptySchema } from '../schema-designer/utils'

const logger = debug('app:selectTable')

type SnowflakeTableSchemaResponse = {
  getSnowflakeTableSchema?: SnowflakeTableColumnInput[]
  errors?: any[]
  message?: string
}

type ClouderaTableSchemaResponse = {
  getClouderaTableSchema?: SnowflakeTableColumnInput[]
  errors?: any[]
  message?: string
}

function isSnowflakeTableSchemaResponse(
  response: SnowflakeTableSchemaResponse | ClouderaTableSchemaResponse,
): response is SnowflakeTableSchemaResponse {
  return (response as SnowflakeTableSchemaResponse).getSnowflakeTableSchema !== undefined
}

export const StepSelectTable: StepComponent<UniCreateStepValues> = ({
  stepValues,
  setCurrStepIndex,
  setStepValues,
  currStepIndex,
  submitStep,
  form,
}) => {
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()
  const api = useApi()
  const getTableSchemaMutation = useMutation<any, Error, { connectionInput: any; tableName: string }>({
    mutationFn: async ({ connectionInput, tableName }: any) => {
      if (stepValues.snowflakeHost) {
        return api.getSnowflakeTableSchema<{ getSnowflakeTableSchema?: SnowflakeTableColumnInput[] }>({
          connectionInput,
          tableName,
        })
      } else if (stepValues.clouderaJdbcUrl) {
        return api.getClouderaTableSchema<{ getClouderaTableSchema?: SnowflakeTableColumnInput[] }>({
          connectionInput,
          tableName,
        })
      }
    },
    onError: (error: Error) => notify.error(`Error fetching table schema: ${error}`),
    onSuccess: (response: any) => {
      logger('response', response)
      if (response.message) {
        notify.error(`Error fetching table schema: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }

      const vendiaSchema = getEmptySchema()

      setStepValues({
        ...stepValues,
        selectedTableColumns: isSnowflakeTableSchemaResponse(response)
          ? response.getSnowflakeTableSchema
          : response.getClouderaTableSchema,
        schema: JSON.stringify(vendiaSchema),
      })
      setCurrStepIndex(currStepIndex + 1)
    },
  })

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Select a Table'
        description={'Select the table containing the data set you want to share.'}
      />
      <ScrollableStepContent verticallyCentered>
        <div className='w-full max-w-4xl'>
          <div className='flex w-full flex-col gap-4'>
            <ListboxField
              name='selectedTable'
              label='Table'
              description='Select the table containing the data set you want to share.'
              options={stepValues.availableTables.map((v: string) => ({ value: v, label: v }))}
              validators={isRequiredOnBlur}
              useNestedLabel
              form={form}
            />
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          kind='secondary'
          className='w-28'
          onClick={() => setCurrStepIndex(currStepIndex - 1)}
          data-testid='back-button'
        >
          Back
        </Button>
        <Button
          kind='primary'
          className='w-28'
          icon={getTableSchemaMutation.isPending ? 'refresh' : null}
          iconProps={{
            isSpinning: getTableSchemaMutation.isPending,
          }}
          disabled={getTableSchemaMutation.isPending}
          onClick={async () => {
            const previousTable = stepValues.selectedTable
            const dataProductValues = form.state.values.products
            const newTable = form.state.values.selectedTable
            logger('dataProductValues', dataProductValues)
            logger('previousTable', previousTable)
            logger('newTable', newTable)
            if (previousTable && previousTable !== newTable && dataProductValues) {
              // TODO: use a nicer modal here
              const confirm = window.confirm(
                'Changing the table will reset the Data products and Sharing permissions you have already configured. Are you sure you want to continue?',
              )
              if (!confirm) {
                return
              }
              form.setFieldValue('products', [])
            }
            // Stay on same step, validate form
            const latestStepValues = await submitStep({ nextStepIndex: currStepIndex })
            if (latestStepValues !== null) {
              const selectedTable = latestStepValues.selectedTable
              logger('selectedTable', selectedTable)

              let connectionInput
              if (stepValues.snowflakeHost) {
                connectionInput = {
                  authType: 'PASSWORD',
                  account: stepValues.snowflakeHost,
                  username: stepValues.snowflakeUsername,
                  password: stepValues.snowflakePassword,
                  warehouse: stepValues.snowflakeWarehouse,
                  database: stepValues.snowflakeDatabase,
                  schema: stepValues.snowflakeSchema,
                  role: stepValues.snowflakeRole,
                }
              } else if (stepValues.clouderaJdbcUrl) {
                connectionInput = {
                  jdbcUrl: stepValues.clouderaJdbcUrl,
                  atlasUrl: stepValues.clouderaAtlasUrl,
                  username: stepValues.clouderaUsername,
                  password: stepValues.clouderaPassword,
                  database: stepValues.clouderaDatabase,
                }
              }
              getTableSchemaMutation.mutate({ connectionInput, tableName: selectedTable })
            }
          }}
          data-testid='next-button'
        >
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
