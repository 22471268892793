import { FieldApi } from '@tanstack/react-form'
import {
  DataPolicyColumnEffect,
  DataPolicyOperator,
  DataProductRowPolicyInput,
  SnowflakeTableColumnInput,
} from '@vendia/management-api-types'
import clsx from 'clsx'
import debug from 'debug'
import { useCallback } from 'react'
import { LakehouseEditStepValues } from 'src/types/lakehouse'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import {
  dataProductOperatorOptions,
  getColumnOptions,
  getColumnPermissionOptions,
  rowPermissionOptions,
} from 'src/utils/lakehouse/flows'
import { getCurrentProductFieldValue, setCurrentProductFieldValue } from 'src/utils/lakehouse/products'

import Button from '../buttons/button'
import Card from '../containers/card'
import ListboxField from '../fields/listbox.field'
import TextField from '../fields/text.field'
import { StepComponent, StepComponentProps } from '../flows/types'
import Icon from '../icons/icon'
import { ListOption } from '../inputs/listbox.input'

const logger = debug('app:sharingPermissions')

const calcWidthForSelect = (options: SnowflakeTableColumnInput[]) => {
  const longestOption = options
    .map((o) => o.name)
    .sort((a, b) => (a.length >= b.length ? 1 : -1))
    .pop()

  const len = longestOption?.length ?? 20
  return { minWidth: `${len + 5}ch`, width: `${len + 5}ch` }
}

type ColumnConfigProps = StepComponentProps<LakehouseEditStepValues> & {
  defaultColumnPolicyEffect?: DataPolicyColumnEffect
  snowflakeColumnOptions: ListOption[]
}

const ColumnConfig = ({ form, stepValues, defaultColumnPolicyEffect }: ColumnConfigProps) => {
  const productIndex = stepValues.sharingPermissionsEditIndex
  const name = `products.${productIndex}.columnPolicies`
  const columnPolicies = form.getFieldValue(name)

  const columnPermissionOptions = getColumnPermissionOptions(defaultColumnPolicyEffect)
  const snowflakeColumnOptions = getColumnOptions(stepValues.selectedTableColumns, columnPolicies)

  async function addColumnPolicy(field: FieldApi<any, any>) {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue({ name: '', effect: '', value: '' })
    }
  }

  return (
    <div className='flex w-full flex-1 flex-col'>
      <form.Field name={name} mode='array'>
        {(field) => (
          <>
            {field.state.value?.length === 0 && (
              <h1 className='m-2 self-center text-2xl'>{`All columns will be ${defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow ? 'shared' : 'excluded'}`}</h1>
            )}
            {field.state.value?.length > 0 && (
              <div
                className={clsx(
                  'mb-4 flex h-5/6 w-full max-w-7xl flex-col justify-center gap-3',
                  // h.scroll entire rows area on smaller screens
                  'min-w-[1100px]',
                )}
              >
                {field.state.value?.map((policy: any, index: number) => {
                  const fieldPrefix = `${name}.${index}`

                  return (
                    <div
                      key={index}
                      className={'bg-uibg-1 rounded-md border border-indigo-300 p-4'}
                      data-testid={`column-sharing-policy-${index}`}
                    >
                      <div className='flex items-center justify-stretch gap-4'>
                        <div className='flex w-full items-start justify-items-stretch gap-4'>
                          <span className='text-neutral-8 self-center'>When</span>
                          <ListboxField
                            name={`${fieldPrefix}.name`}
                            label='Attribute'
                            options={snowflakeColumnOptions}
                            data-testid={`column-policy-attribute-select-${index}`}
                            style={calcWidthForSelect(stepValues.selectedTableColumns)}
                            validators={isRequiredOnBlur}
                            useNestedLabel
                            form={form}
                          />
                          <span className='text-neutral-8 self-center'>is present,</span>
                          <ListboxField
                            name={`${fieldPrefix}.effect`}
                            label='Action'
                            options={columnPermissionOptions}
                            data-testid={`column-policy-action-select-${index}`}
                            className='min-w-80'
                            validators={isRequiredOnBlur}
                            useNestedLabel
                            form={form}
                          />
                          {[DataPolicyColumnEffect.MaskExceptLast, DataPolicyColumnEffect.Replace].includes(
                            policy?.effect,
                          ) && (
                            <>
                              <TextField
                                name={`${fieldPrefix}.value`}
                                label={
                                  form.getFieldValue(`${fieldPrefix}.effect`) === DataPolicyColumnEffect.MaskExceptLast
                                    ? 'Constraint'
                                    : 'Replacement'
                                }
                                placeholder='Enter replacement value'
                                data-testid={`column-policy-value-input-${index}`}
                                className='mt-1 min-w-20 grow'
                                validators={{
                                  onChangeListenTo: [`${fieldPrefix}.effect`],
                                  onBlur: ({ value }: any) => {
                                    if (
                                      columnPolicies?.[index]?.effect === DataPolicyColumnEffect.MaskExceptLast &&
                                      Number.isNaN(parseInt(value))
                                    ) {
                                      return 'Must be a number'
                                    }
                                  },
                                }}
                                useNestedLabel
                                form={form}
                              />
                              {form.getFieldValue(`${fieldPrefix}.effect`) ===
                                DataPolicyColumnEffect.MaskExceptLast && (
                                <span className='text-neutral-8 self-center'>characters</span>
                              )}
                            </>
                          )}
                        </div>
                        <div className={'px-2'}>
                          <Button kind='link' icon='trash' onClick={() => field.removeValue(index)} />
                        </div>
                      </div>
                      {field.state.meta.errors?.length > 0 && (
                        <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                          <Icon name='error' size='xs' />
                          {field.state.meta.errors.at(0)}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='flex w-full max-w-4xl pb-6'>
              <Button
                kind={'secondary'}
                onClick={(e) => {
                  e.preventDefault()
                  addColumnPolicy(field)
                }}
                icon='plus-m'
                iconSize={14}
              >
                {field.state.value.length ? 'Add another' : 'Add column policy'}
              </Button>
            </div>
          </>
        )}
      </form.Field>
    </div>
  )
}

type RowConfigProps = StepComponentProps<LakehouseEditStepValues> & {
  snowflakeColumnOptions: ListOption[]
}

const RowConfig = ({ form, stepValues }: RowConfigProps) => {
  const productIndex = stepValues.sharingPermissionsEditIndex
  const name = `products.${productIndex}.rowPolicies`
  const rowPolicies = form.getFieldValue(name)
  const snowflakeColumnOptions = getColumnOptions(stepValues.selectedTableColumns, rowPolicies)

  async function addRowPolicy(field: FieldApi<any, any>) {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue({ name: '', effect: '', operator: '', value: '' })
    }
  }

  return (
    <div className='flex w-full flex-1 flex-col'>
      <form.Field name={name} mode='array'>
        {(field) => (
          <>
            {field.state.value.length === 0 && <h1 className='m-2 self-center text-2xl'>No rows will be filtered</h1>}
            {field.state.value.length > 0 && (
              <div
                className={clsx(
                  'mb-4 flex h-5/6 w-full max-w-7xl flex-col justify-center gap-4',
                  // h.scroll entire rows area on smaller screens
                  'min-w-[1100px]',
                )}
              >
                {field.state.value.map((policy: DataProductRowPolicyInput, index: number) => {
                  const fieldPrefix = `${name}.${index}`
                  logger('row policy', index, policy)

                  const requireConstraint =
                    policy.operator &&
                    ![DataPolicyOperator.IsNotNull, DataPolicyOperator.IsNull].includes(policy.operator)

                  return (
                    <div
                      key={index}
                      className={'bg-uibg-1 rounded-md border border-indigo-300 p-4'}
                      data-testid={`row-sharing-policy-${index}`}
                    >
                      <div className='flex items-center justify-stretch gap-3'>
                        <div className='flex w-full items-start justify-items-stretch gap-4'>
                          <ListboxField
                            name={`${fieldPrefix}.effect`}
                            label='Action'
                            options={rowPermissionOptions}
                            data-testid={`row-policy-action-select-${index}`}
                            className='min-w-40'
                            validators={isRequiredOnBlur}
                            useNestedLabel
                            form={form}
                          />
                          <span className='text-neutral-8 self-center'>data, where</span>
                          <ListboxField
                            name={`${fieldPrefix}.name`}
                            label='Attribute'
                            options={snowflakeColumnOptions}
                            data-testid={`row-policy-attribute-select-${index}`}
                            style={calcWidthForSelect(stepValues.selectedTableColumns)}
                            validators={isRequiredOnBlur}
                            useNestedLabel
                            form={form}
                          />
                          <span className='text-neutral-8 self-center'>is</span>
                          <ListboxField
                            name={`${fieldPrefix}.operator`}
                            label='Operator'
                            options={dataProductOperatorOptions}
                            data-testid={`row-policy-operator-select-${index}`}
                            className='min-w-[13rem]'
                            validators={isRequiredOnBlur}
                            useNestedLabel
                            form={form}
                          />
                          {requireConstraint && (
                            <TextField
                              name={`${fieldPrefix}.value`}
                              label='Constraint'
                              data-testid={`row-policy-value-input-${index}`}
                              className='mt-1 min-w-20 grow'
                              validators={isRequiredOnBlur}
                              useNestedLabel
                              form={form}
                            />
                          )}
                        </div>
                        <div className={'px-2'}>
                          <Button kind='link' icon='trash' onClick={() => field.removeValue(index)} />
                        </div>
                      </div>
                      {field.state.meta.errors?.length > 0 && (
                        <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                          <Icon name='error' size='xs' />
                          {field.state.meta.errors.at(0)}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}

            <div className='flex w-full max-w-4xl pb-6'>
              <Button
                kind={'secondary'}
                onClick={(e) => {
                  e.preventDefault()
                  addRowPolicy(field)
                }}
                icon='plus-m'
                iconSize={14}
              >
                {field.state.value.length > 0 ? 'Add another' : 'Add row policy'}
              </Button>
            </div>
          </>
        )}
      </form.Field>
    </div>
  )
}

export const EditSharingPermissions: StepComponent<LakehouseEditStepValues> = (props) => {
  const toggleColumnDefault = useCallback(() => {
    const field = 'defaultColumnPolicyEffect'
    const current = getCurrentProductFieldValue(props.stepValues, field)
    const newValue =
      current === DataPolicyColumnEffect.Allow ? DataPolicyColumnEffect.Exclude : DataPolicyColumnEffect.Allow

    props.setStepValues(setCurrentProductFieldValue(props.stepValues, field, newValue))
  }, [props.stepValues])

  const snowflakeColumnOptions = getColumnOptions(props.stepValues.selectedTableColumns, [])
  const defaultColumnPolicyEffect = getCurrentProductFieldValue<DataPolicyColumnEffect>(
    props.stepValues,
    'defaultColumnPolicyEffect',
  )

  return (
    <div className='flex w-full flex-col items-center justify-center py-4'>
      <Card
        overflow='visible'
        className='text-neutral-8 mb-4 w-full max-w-7xl flex-1 flex-col'
        title={<span className='text-base font-semibold'>Column permissions</span>}
        actions={
          <Button kind='toggle' onClick={toggleColumnDefault}>
            Default to&nbsp;<strong>{defaultColumnPolicyEffect?.toLowerCase()}</strong>&nbsp;all columns
          </Button>
        }
        data-testid='column-permissions-card'
      >
        <ColumnConfig
          {...props}
          defaultColumnPolicyEffect={defaultColumnPolicyEffect}
          snowflakeColumnOptions={snowflakeColumnOptions}
        />
      </Card>

      <Card
        overflow='visible'
        className='text-neutral-8 w-full max-w-7xl flex-1 flex-col'
        title={<span className='text-base font-semibold'>Row permissions</span>}
        data-testid='row-permissions-card'
      >
        <RowConfig {...props} snowflakeColumnOptions={snowflakeColumnOptions} />
      </Card>
    </div>
  )
}
