import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { UniCreateStepValues } from 'src/types/lakehouse'

import { StepId } from '../config'

export const StepChooseSource: StepComponent<UniCreateStepValues> = (props) => {
  const { currStepIndex, submitStep } = props

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Choose your data source'
        description={'What data would you like to sync?'}
      />
      <ScrollableStepContent verticallyCentered>
        <div className='flex w-1/3 flex-col gap-4'>
          <button onClick={() => submitStep({ nextStepId: StepId.ConnectSnowflake })}>
            <Card>
              <div className='flex items-center gap-4'>
                <div className='rounded-full border p-2'>
                  <img src='/images/vendor/snowflake.png' alt='Snowflake' className='h-12 w-12' />
                </div>
                <div className='text-left'>
                  <h2 className='text-lg font-semibold'>Snowflake</h2>
                  <p className='text-sm text-gray-500'>Connect to your data in Snowflake</p>
                </div>
              </div>
            </Card>
          </button>
          <button onClick={() => submitStep({ nextStepId: StepId.ConnectCloudera })}>
            <Card>
              <div className='flex items-center gap-4'>
                <div className='rounded-full border p-2'>
                  <img src='/images/vendor/cloudera.png' alt='Snowflake' className='h-12 w-12' />
                </div>
                <div className='text-left'>
                  <h2 className='text-lg font-semibold'>Cloudera</h2>
                  <p className='text-sm text-gray-500'>Connect to your data in Snowflake</p>
                </div>
              </div>
            </Card>
          </button>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button
          kind='secondary'
          className='w-28'
          // onClick={() => setSearchParams({ flow: CreateFlow.SELECT_TYPE })}
          onClick={() => submitStep({ nextStepIndex: currStepIndex - 1 })}
          data-testid='back-button'
        >
          Back
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
