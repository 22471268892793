import { ForwardedRef, forwardRef, RefObject, useRef } from 'react'

import NumberInput, { NumberInputProps } from '../inputs/number.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, onChangeHandler, useRemoveFieldOnUnmount } from './utils'

export type NumberFieldProps = NumberInputProps & FormFieldProps

const NumberField = (
  {
    form,
    name,
    wrapperClassName,
    labelClassName,
    descriptionClassName,
    validators,
    isArrayField,
    arrayFieldName,
    arrayFieldIndex,
    defaultValue,
    onChange,
    onBlur,
    removeFieldOnUnmount,
    ...rest
  }: NumberFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const innerRef = useRef<HTMLInputElement>(null)
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  const inputRef = ref ?? innerRef

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      labelClassName={labelClassName}
      descriptionClassName={descriptionClassName}
      inputRef={inputRef as RefObject<HTMLInputElement>}
      {...rest}
    >
      <form.Field name={name} validators={validators} defaultValue={defaultValue}>
        {(field) => (
          <NumberInput
            {...rest}
            ref={inputRef}
            name={name}
            value={field.state.value}
            hasError={field.state.meta.errors?.length > 0}
            onBlur={onBlurHandler(field, onBlur)}
            onChange={onChangeHandler<number>(field, onChange)}
          />
        )}
      </form.Field>
    </FieldSet>
  )
}

export default forwardRef<HTMLInputElement, NumberFieldProps>(NumberField)
