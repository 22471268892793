import 'notyf/notyf.min.css'
import 'graphiql/graphiql.css'
import '@graphiql/plugin-explorer/dist/style.css'
import './global.css'

import Auth from '@aws-amplify/auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import getAppRouter from './app.router'
import { enableMocking } from './mock/enable-mocking'
import UploadFileStatusProvider from './pages/files/upload-file-status-provider'
import AuthContextProvider from './utils/auth/auth-context'
import { getLoggedInNode, getLoggedInNodeAmplifyConfig } from './utils/auth/logged-in-node'

const signedInNode = getLoggedInNode()
const amplifyConfig = getLoggedInNodeAmplifyConfig({ signedInNode })
Auth.configure(amplifyConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
})

function initReact() {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <UploadFileStatusProvider>
            <RouterProvider router={getAppRouter()} />
          </UploadFileStatusProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </RecoilRoot>,
  )
}

enableMocking().then(initReact)
