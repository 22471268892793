import { FieldApi } from '@tanstack/react-form'
import { useMemo } from 'react'
import RadioGroup from 'src/components/inputs/radio-group.input'
import { useGetOrg } from 'src/utils/hooks/use-get-org'

import { logger } from '../org-invite'
import { getRoleTemplates } from './role-templates'

type RoleTemplateRadioGroupProps = {
  name: string
  field: FieldApi<any, any>
  label: string | JSX.Element
  labelDescription?: string | JSX.Element
}
export const RoleTemplateRadioGroup = ({ name, field, label, labelDescription }: RoleTemplateRadioGroupProps) => {
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization

  // Get all the role templates
  const roleTemplates = useMemo(() => {
    if (!org?.orgId || !org?.domains) return []
    const templates = getRoleTemplates({ orgId: org?.orgId, orgDomains: org?.domains })
    logger('roleTemplates', templates)
    return templates
  }, [org?.orgId, org?.domains])

  // Map the role templates to the options for the radio group
  const roleOptions = roleTemplates.map((t) => ({
    value: t.name,
    label: t.name,
    description: t.description,
  }))
  return (
    <RadioGroup
      name={name}
      label={label}
      description={labelDescription}
      value={field.state.value}
      hasError={field.state.meta.errors?.length > 0}
      options={roleOptions}
      onChange={field.handleChange}
    />
  )
}
