import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Invite } from '@vendia/management-api-types'
import debug from 'debug'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Balancer from 'react-wrap-balancer'
import { useRecoilValue } from 'recoil'
import safe from 'safe-await'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import TextListField from 'src/components/fields/text-list.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import Loader from 'src/components/loaders/page-loader'
import BasicModal from 'src/components/modals/modal.basic'
import StatusPill from 'src/components/pills/status-pill'
import analytics, { EVENTS } from 'src/utils/analytics'
import inputValidation from 'src/utils/form/input.validation'
import { createOnBlurValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import useListUnis, { refetchUnis } from 'src/utils/hooks/use-list-unis'
import { captureException } from 'src/utils/misc/sentry'
import notify from 'src/utils/notify'
import { userState } from 'src/utils/state'
import { validateSchemaAndDisplayErrors } from 'src/utils/validate-schema'

import { StepId } from '../config'
import { buildUniFqn, buildUniNameFromAlias, generateNodesForApi } from '../utils'

const logger = debug('app:invite-partners')

interface InviteResponse {
  invite: Invite
  errors: {
    message: string
  }[]
}

type CreateErrorInfo = {
  title: string
  subTitle: string
  lines: string[]
} | null

export const StepInvitePartners: StepComponent = ({ form, currStepIndex, submitStep }) => {
  const navigate = useNavigate()
  const userData = useRecoilValue<any>(userState)
  const [createLoading, setCreateLoading] = useState(false) // TODO: fix this
  const { listUnisQuery } = useListUnis()
  const { data: unis } = listUnisQuery
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const userId = getCurrentVendiaUserQuery?.data?.getUser?.userId
  const queryClient = useQueryClient()
  const api = useApi()
  const inviteMutation = useMutation<InviteResponse, Error, { userId: string; uni: string }>({
    mutationFn: api.invite.bind(api),
  })
  const [createErrorInfo, setCreateErrorInfo] = useState<CreateErrorInfo>(null)
  const [showCreateCompleteMessage, setShowCreateCompleteMessage] = useState(false)

  async function createUni(updatedStepValues: any) {
    setCreateLoading(true)

    logger('createUni!')
    logger('stepValues:', updatedStepValues)

    const { uniName, uniNamespace, nodes, schema, schemaTemplate, enableFinancialServices, invites, randomSuffix } =
      updatedStepValues

    // The "name" user entered is actually stored as the alias
    const alias = uniName
    const generatedUniName = buildUniNameFromAlias(alias, randomSuffix)
    const uniFqn = buildUniFqn(generatedUniName, uniNamespace)

    // This SHOULD be valid, but let's check once more here (plus we want a few details for analytics)
    const [e, schemaJson] = await validateSchemaAndDisplayErrors(schema)
    if (e) {
      setCreateLoading(false)
      return
    }

    const nodesForApi = generateNodesForApi(userData.email, nodes)
    logger({ nodesForApi })

    let results: any[] = []
    const [error, resp] = await safe(
      api.register({
        name: uniFqn,
        alias: alias,
        schema: schema,
        initState: '{}',
        nodes: nodesForApi,
        sku: enableFinancialServices ? 'SHARE_FINANCIAL_SERVICES' : 'SHARE',
        // asRole
      }),
    )

    if (error) {
      logger('API err', error)
      // Capture api errors
      captureException(error)
    }

    results = results.concat(resp)
    logger('Creation results:', results)

    const apiErrors = results
      .filter((res) => {
        return res.errors
      })
      .reduce((acc, curr) => {
        logger('Creation errors', curr.errors)
        acc = acc.concat(curr.errors)
        return acc
      }, [])

    setCreateLoading(false)

    if (apiErrors.length) {
      logger('apiErrors', apiErrors)
      const plural = apiErrors.length > 1 ? 's' : ''

      const errorsFromApi = apiErrors.map((apiError: Error) => apiError.message)
      setCreateErrorInfo({
        title: 'Uni creation errors',
        subTitle: `Encountered the following error${plural} while attempting to deploy this Uni:`,
        lines: errorsFromApi,
      })

      logger('API errors', errorsFromApi)

      // Track failure. @TODO send error messages through?
      analytics.track(EVENTS.UNI_CREATE_FAILED)

      // exit early
      return false
    }

    // Success continue
    analytics.track(EVENTS.UNI_CREATED, {
      uniName: uniFqn,
      schemaTemplate, // eg, "Shopping List"
      schemaTitle: schemaJson?.title,
      schemaEntityCount:
        typeof schemaJson?.properties === 'object' && schemaJson?.properties !== null
          ? Object.keys(schemaJson.properties).length
          : null,
      nodeCount: nodesForApi.length,
      authType: nodesForApi.map((node) => node?.settings?.apiSettings?.auth?.authorizerType),
    })

    // Invalidate queries
    refetchUnis({ queryClient })

    // Send out Uni invites (if any)
    invites.forEach(async (invite: { email: string }) => {
      /* We allow empty values for invite email so they can just skip */
      if (inputValidation.isEmail.pattern.test(invite.email) === false) {
        console.log('Skipping sending invite to empty/invalid email', invite.email)
        return
      }
      const response = await inviteMutation.mutateAsync({
        uni: uniFqn,
        userId: invite.email,
      })
      if (response?.errors?.length) {
        logger('Invite error', response.errors)
        notify.error(
          `There was a problem sending an invite to ${invite.email}.<br/><br/>${response.errors?.[0]?.message}`,
        )
      } else {
        //track successful invite
        analytics.track(EVENTS.UNI_INVITE)
      }
    })

    // Update the UI
    setShowCreateCompleteMessage(true)
    setTimeout(() => {
      navigate(`/uni/${uniFqn.includes('unis.vendia.net') ? generatedUniName : uniFqn}`)
    }, 6000)
  }

  if (!unis || !userId) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <StepWrapper>
      <BasicModal
        title={createErrorInfo?.title}
        isOpen={createErrorInfo !== null}
        onClose={() => setCreateErrorInfo(null)}
        buttons={
          <Button
            key='cancel'
            kind='secondary'
            onClick={() => {
              setCreateErrorInfo(null)
            }}
          >
            Close
          </Button>
        }
      >
        <p className='mb-2 font-bold'>{createErrorInfo?.subTitle}</p>
        <ul>
          {createErrorInfo?.lines?.map((line, i) => {
            return <li key={i}>{line}</li>
          })}
        </ul>
      </BasicModal>
      <ScrollableStepContent>
        {showCreateCompleteMessage ? (
          <div className='mt-16 flex w-full flex-col items-center p-4 lg:px-8'>
            <div className='flex flex-col items-center text-center'>
              <div className='text-3xl font-bold'>Success! Your new Uni is deploying! 🚀</div>
              <p className='mt-12 max-w-md text-center'>
                <Balancer>
                  Your Uni will display this <StatusPill status={'DEPLOYING'} /> badge for the next 5 to 10 minutes
                  after which it will be ready to use.
                </Balancer>
              </p>
              <p className='mt-12 animate-pulse text-center text-sm text-gray-800'>
                Redirecting to Uni page momentarily...
              </p>
            </div>
          </div>
        ) : (
          <div className='flex w-full flex-col items-center p-4 lg:px-8'>
            <StepContentHeader
              className='max-w-4xl'
              title={
                <span>
                  Invite partners to join your Uni<span className='text-neutral-7 font-normal'> (Optional)</span>
                </span>
              }
              description={`Up to 20 partners can join a Uni. You can invite additional partners at any time.`}
            />

            <div className={'mb-6 mt-12 flex w-full max-w-2xl flex-col gap-4'}>
              <Card padding='xl' onWhiteBg className={'flex w-full flex-col'}>
                <TextListField
                  name='invites'
                  fieldNameKey='email'
                  addNewText='Add another partner'
                  label='Partner email'
                  defaultValue={['']}
                  description='The email address of the partner you want to invite. You should use the email address they used to sign up for Vendia Share.'
                  form={form}
                  fieldValidators={createOnBlurValidator(inputValidation.isEmail)}
                />
              </Card>
            </div>
          </div>
        )}
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button
          disabled={createLoading || showCreateCompleteMessage}
          kind='tertiary'
          onClick={() => navigate('/')}
          data-testid='cancel-button'
        >
          Cancel
        </Button>
        {currStepIndex !== 0 && (
          <Button
            disabled={createLoading || showCreateCompleteMessage}
            className='w-28'
            kind='secondary'
            onClick={() => submitStep({ nextStepIndex: currStepIndex - 1 })}
            data-testid='previous-button'
          >
            Previous
          </Button>
        )}
        <Button
          kind='primary'
          className='min-w-[112px]' // Let it get wider when text updates to "Creating Uni..."
          onClick={async () => {
            // Navigate to the same step - this triggers form validation and
            // adds forms values to stepValues which we read from in createUni
            logger('submitting step')
            const latestStepValues = await submitStep({ nextStepId: StepId.InvitePartners })
            if (latestStepValues !== null) {
              createUni(latestStepValues)
            }
          }}
          data-testid='create-new-uni-button'
          disabled={createLoading || showCreateCompleteMessage}
        >
          {createLoading ? 'Creating Uni...' : 'Finish'}
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
