import { Description, Label, Radio, RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import Balancer from 'react-wrap-balancer'
import Button from 'src/components/buttons/button'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import Pill from 'src/components/pills/pill'

import { templates } from '../templates'

const logger = debug('app:selectEditMode')

const SCHEMA_DESIGNER = 'Schema Designer'
const JSON_EDITOR = 'JSON Editor'

function RadioOption({
  checked,
  name,
  description,
  icon,
  isTemplate = false,
}: {
  checked: boolean
  name: string
  description: string
  icon: string
  isTemplate?: boolean
}) {
  const wrapperClasses = clsx(!checked && 'ring-neutral-4 ring-2', checked && 'ring-information-9 bg-neutral-0 ring-4')
  const iconClasses = clsx(!isTemplate && 'h-24 w-24', isTemplate && 'h-16 w-16')

  return (
    <div className={`relative grid h-full place-content-center rounded-xl p-6 ring-inset ${wrapperClasses}`}>
      {name === SCHEMA_DESIGNER && (
        <div className='absolute right-4 top-4'>
          <Pill kind='info' className='ml-2'>
            Recommended
          </Pill>
        </div>
      )}
      <Label className={`relative flex flex-col items-center justify-center`}>
        <div className={iconClasses}>
          <img className={iconClasses} src={`/images/${icon}`} alt={icon} />
        </div>
        <div className='flex items-center'>
          <div className={`mt-2 flex items-baseline font-semibold ${!isTemplate ? 'mb-1 text-xl' : ''}`}>{name}</div>
        </div>
        <Description className={'text-neutral-8 text-xs'}>
          <Balancer>{description}</Balancer>
        </Description>
        <div className='mt-4'>
          {checked ? (
            <div className='ring-information-9 grid h-8 w-8 place-content-center rounded-full ring-2 ring-inset'>
              <div className='bg-information-9 h-4 w-4 rounded-full' />
            </div>
          ) : (
            <div className={`ring-information-9 h-8 w-8 rounded-full ring-2 ring-inset`} />
          )}
        </div>
      </Label>
    </div>
  )
}

export const StepSchemaEditMode: StepComponent = ({ stepValues, setCurrStepIndex, setStepValues, currStepIndex }) => {
  const navigate = useNavigate()

  const [value, setValue] = useState(stepValues.showJsonEditor ? JSON_EDITOR : SCHEMA_DESIGNER)

  function handleChangeStep() {
    logger('StepSchemaEditMode value is', value)
    const template = templates.find((t) => t.name === value)
    if (template) {
      setStepValues({
        ...stepValues,
        schema: template.schema,
      })
    }
    if (value === SCHEMA_DESIGNER) {
      setStepValues({
        ...stepValues,
        showJsonEditor: false,
      })
    }
    if (value === JSON_EDITOR) {
      setStepValues({
        ...stepValues,
        showJsonEditor: true,
      })
    }
  }

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center p-4 lg:px-8'>
          <StepContentHeader
            title='How would you like to design your data model?'
            description={'Select an option and begin designing your data model. You can always change this later.'}
          />
          <RadioGroup value={value} onChange={setValue} className={'flex w-full flex-1 flex-col px-16'}>
            <div className='my-3 w-full font-bold'>Select the option that works best for you</div>
            <div className={'flex h-72 gap-8'}>
              <Radio value={SCHEMA_DESIGNER} className={'w-1/2'}>
                {({ checked }) => (
                  <RadioOption
                    checked={checked}
                    name={SCHEMA_DESIGNER}
                    description={'Use our visual schema designer to build your data model. Supports CSV upload.'}
                    icon={'upload-spreadsheet.svg'}
                  />
                )}
              </Radio>
              <Radio value={JSON_EDITOR} className={'w-1/2'}>
                {({ checked }) => (
                  <RadioOption
                    checked={checked}
                    name={JSON_EDITOR}
                    description={'Work directly with JSON to create your data model'}
                    icon={'laptop-spreadsheet.svg'}
                  />
                )}
              </Radio>
            </div>
            <div className='mb-3 mt-8 w-full font-bold'>Or explore and learn using one of our demo templates</div>
            <div className={'h48 flex gap-8'}>
              {templates.slice(0, 3).map((template) => (
                <Radio key={template.name} value={template.name} className={'w-1/3'}>
                  {({ checked }) => (
                    <RadioOption
                      isTemplate
                      checked={checked}
                      name={template.name}
                      description={template.description}
                      icon={template.icon}
                    />
                  )}
                </Radio>
              ))}
            </div>
          </RadioGroup>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          className='w-28'
          kind='secondary'
          onClick={() => {
            handleChangeStep()
            setCurrStepIndex(currStepIndex - 1)
          }}
          data-testid='previous-button'
        >
          Previous
        </Button>
        <Button
          kind='primary'
          className='w-28'
          onClick={() => {
            handleChangeStep()
            setCurrStepIndex(currStepIndex + 1)
          }}
          data-testid='next-button'
        >
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
