/* Uni templates */
import productsData from './data/products'
import shapesData from './data/shapes'
import shoppingCart from './data/shopping-cart'
import trackAndTraceData from './data/track-and-trace'

type Template = {
  name: string
  icon: string
  description: string
  schema: string
}

export const templates: Template[] = [
  {
    name: 'Track and Trace',
    icon: 'track-and-trace.svg',
    description: 'Deploy a track and trace application',
    schema: trackAndTraceData.schema,
  },
  {
    name: 'Shopping List',
    icon: 'shopping-list.svg',
    description: 'Deploy a distributed shopping list',
    schema: shoppingCart.schema,
  },
  {
    name: 'Product database',
    icon: 'product-db.svg',
    description: 'Deploy a sample uni with product skus',
    schema: productsData.schema,
  },
  {
    name: 'Shapes Demo',
    icon: 'shapes-demo.svg',
    description: 'Deploy a sample uni with shapes',
    schema: shapesData.schema,
  },
]

export async function getTemplateHelper(template: Template) {
  let schema
  if (template.schema.startsWith('https')) {
    const resp = await fetcher(template.schema, 'schema')
    schema = JSON.stringify(resp.data, null, 2)
  } else {
    schema = template.schema
  }
  return { schema }

  async function fetcher(url: string, type: string) {
    let res
    try {
      res = await fetch(url)
      res = await res.json()
    } catch (err) {
      console.log('no dice', err)
    }
    return {
      type: type,
      data: res,
    }
  }
}
