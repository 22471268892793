import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Button from 'src/components/buttons/button'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { useDevSettings } from 'src/pages/developer-settings.page'
import { UniCreateStepValues } from 'src/types/lakehouse'
import { createOnBlurValidator } from 'src/utils/form/validation'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import { selectedRoleState } from 'src/utils/state'

import { CreateFlow } from '../config'
import { getUniResourceFromString, UniResource } from '../uni-resource'

export const StepCreateLakehouse: StepComponent<UniCreateStepValues> = ({ form, submitStep, currStepIndex }) => {
  const uniNameRef = useRef<HTMLInputElement>(null)
  const { isLoading, devSettings } = useDevSettings()
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()
  const [eligibleNamespaces, setEligibleDomains] = useState<UniResource[]>([])
  const [selectedRole] = useRecoilState(selectedRoleState)
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()

  useEffect(() => {
    setTimeout(() => {
      uniNameRef.current?.focus()
    }, 100)
  }, [uniNameRef.current])

  useEffect(() => {
    const role = getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((r) => r.name === selectedRole.name)

    if (!role) {
      getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((r) => r.isDefault || r.name === 'default')
    }

    if (role) {
      const eligibleUniResources: UniResource[] = role.capabilities
        // grab all create capabilities
        .filter((capability) => ['UNI_ALL', 'UNI_CREATE'].includes(capability.action))
        // transform UniResource(*.unis.domain.com#*) into .unis.domain.com
        .flatMap((capability) =>
          capability.resources
            .filter((resource) => resource.startsWith('UniResource'))
            .map((resource) => resource.replace('UniResource(', ''))
            .map((resource) => resource.replace(')', '')),
        )
        // remove duplicates
        .reduce((deduplicatedArray, currentItem) => {
          if (deduplicatedArray.includes(currentItem)) {
            return deduplicatedArray
          }
          return deduplicatedArray.concat([currentItem])
        }, [] as string[])
        // sort to ensure that custom namespaces show up first
        .sort((a, b) => {
          if (a.includes('vendia.net')) {
            return 1
          } else if (b.includes('vendia.net')) {
            return -1
          }
          return 0
        })
        .map((resourceString) => getUniResourceFromString(resourceString))
      setEligibleDomains(eligibleUniResources)
    }
  }, [getCurrentVendiaUserQuery?.data?.getUser?.roles, selectedRole.name])

  const hasOnlyAccessToMultiTenantNamespace =
    eligibleNamespaces.length === 1 &&
    eligibleNamespaces[0].subdomain &&
    eligibleNamespaces[0].domain === 'vendia' &&
    eligibleNamespaces[0].ext === 'net'

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center p-4 lg:px-8'>
          <StepContentHeader
            hasMarginY
            centered
            large
            title='Create data set'
            description={
              'Provide some basic information about your new data set. This is internal and will not be shown to data product consumers.'
            }
          />
          <div className='mb-8 mt-16 flex w-full max-w-4xl flex-col justify-center gap-6'>
            <div className='flex gap-6'>
              <div className='flex-grow'>
                <TextField
                  name='alias'
                  label='Data set name'
                  description={
                    <div className={clsx('text-balance', !hasOnlyAccessToMultiTenantNamespace && 'w-80')}>
                      The name of the data set.
                    </div>
                  }
                  ref={uniNameRef}
                  validators={createOnBlurValidator(
                    {
                      pattern: /^[a-zA-Z][a-zA-Z0-9-._ ]{0,100}$/,
                      message: 'Special characters are not allowed. The data set name must begin with a letter.',
                    },
                    true,
                  )}
                  useNestedLabel
                  form={form}
                />
              </div>
            </div>
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
        {devSettings?.entitlementsUniFlowEnabled ? (
          <Button
            kind='secondary'
            className='w-28'
            onClick={() => setSearchParams({ flow: CreateFlow.SELECT_TYPE })}
            data-testid='back-button'
          >
            Back
          </Button>
        ) : null}
        <Button
          kind='primary'
          className='w-28'
          onClick={() => submitStep({ nextStepIndex: currStepIndex + 1 })}
          data-testid='next-button'
        >
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
