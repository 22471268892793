import { useField } from '@tanstack/react-form'
import { DataPolicyColumnEffect, LakehouseDataProductInput } from '@vendia/management-api-types'
import clsx from 'clsx'
import { useEffect } from 'react'
import { StepId } from 'src/pages/uni-view/lakehouse/config'
import { LakehouseEditStepValues } from 'src/types/lakehouse'
import { AWS_REGIONS } from 'src/utils/csp/regions'
import { isRequiredOnBlur } from 'src/utils/form/validation'

import Button from '../buttons/button'
import MultiListboxField from '../fields/multi-listbox.field'
import TextField from '../fields/text.field'
import { StepComponent } from '../flows/types'
import Loader from '../loaders/page-loader'
import HoverableTooltip from '../messages/hoverable-tooltip'

export const EditDataProducts: StepComponent<LakehouseEditStepValues> = ({
  form,
  setStepValues,
  stepValues,
  submitStep,
  setCurrStepId,
  currStepId,
  currStepIndex,
}) => {
  const name = 'products'
  const field = useField({ name, form, mode: 'array' })
  const value = field?.state?.value

  function addProduct() {
    field.pushValue({
      name: '',
      regions: [],
      columnPolicies: [],
      rowPolicies: [],
      defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
    })
    setStepValues({
      ...stepValues,
      products: value,
    })
  }

  useEffect(() => {
    if (!value || value?.length === 0) {
      addProduct()
    }
  }, [])

  useEffect(() => {
    submitStep({ nextStepIndex: currStepIndex })
  }, [value])

  function validConfiguration(product: any) {
    if (product?.defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude && product?.columnPolicies.length === 0) {
      return 'Current configuration would return no data'
    }
  }

  return (
    <div className='flex w-full flex-1 flex-col p-4 lg:px-8'>
      {value?.length === 0 && (
        <div className='grid place-items-center'>
          <Loader />
        </div>
      )}

      {value?.length > 0 && (
        <div
          className={clsx(
            'flex h-5/6 w-full max-w-7xl flex-col justify-center gap-4',
            // h.scroll entire rows area on smaller screens
            'min-w-[1100px]',
          )}
        >
          {value?.map((product: LakehouseDataProductInput, index: number) => {
            const prefix = `${name}.${index}`

            return (
              <div
                key={index}
                className={'bg-uibg-1 border border-indigo-300 px-8 py-6'}
                data-testid={`data-product-${index}`}
              >
                <div className='flex items-center justify-between gap-4'>
                  <div className='flex items-start gap-4'>
                    <TextField
                      className='min-w-72'
                      name={`${prefix}.name`}
                      label='Name your data product'
                      form={form}
                      validators={isRequiredOnBlur}
                      data-testid={'product-name-input'}
                      useNestedLabel
                    />
                    <MultiListboxField
                      form={form}
                      name={`${prefix}.regions`}
                      label='Make data product available in'
                      options={AWS_REGIONS.map(({ value, label }) => ({ value, label }))}
                      validators={isRequiredOnBlur}
                      data-testid={'product-region-select'}
                      useNestedLabel
                    />
                  </div>
                  <div className='flex items-center gap-2'>
                    <form.Subscribe
                      selector={(state) => [
                        state.values.products[index].name !== '',
                        state.values.products[index].regions.length > 0,
                      ]}
                    >
                      {([nameHasValue, regionAdded]) => (
                        <HoverableTooltip
                          asChild={true}
                          sideOffset={5}
                          disabled={nameHasValue && regionAdded}
                          content={
                            'Please provide a name and select at least one region to enable sharing permissions configuration.'
                          }
                        >
                          <div className='flex w-64 flex-col'>
                            <Button
                              disabled={!nameHasValue || !regionAdded}
                              kind='secondary'
                              onClick={async () => {
                                setStepValues({
                                  ...stepValues,
                                  sharingPermissionsEditIndex: index,
                                  products: value,
                                })
                                setCurrStepId(StepId.SharingPermissions)
                              }}
                              className='w-64'
                            >
                              Configure Sharing Permissions {nameHasValue} {regionAdded}
                            </Button>

                            {validConfiguration(product) && (
                              <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                                {/* <Icon name='error' size='xs' className='' /> */}
                                {validConfiguration(product)}
                              </div>
                            )}
                          </div>
                        </HoverableTooltip>
                      )}
                    </form.Subscribe>
                    {value.length > 1 && (
                      <div className={'px-2'}>
                        <Button kind='link' icon='trash' onClick={() => field.removeValue(index)} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
          <div className='-mt-4 flex w-full max-w-4xl pb-6'>
            <Button
              kind={'secondary'}
              style={{ marginTop: 20 }}
              onClick={(e) => {
                e.preventDefault()
                addProduct()
              }}
              icon='plus-m'
              iconSize={14}
            >
              Add another
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
