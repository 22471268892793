import Auth from '@aws-amplify/auth'
import { useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools'
import { OrgOnboardingStatus, Role } from '@vendia/management-api-types'
import debug from 'debug'
import { decodeJwt } from 'jose'
import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { authRoutes } from './app.router'
import Icon from './components/icons/icon'
import EnterpriseModal from './components/modals/enterprise-modal'
import { UNI_SELECT_ID } from './components/navigation/uni-dropdown'
import { OrgOnboardingModal } from './components/org-onboarding/org-onboarding-modal'
import analytics from './utils/analytics'
import { authContext, AuthStatus, getStoredUserAuth } from './utils/auth/auth-context'
import clearAuth from './utils/auth/clearStorage'
import { checkVerification, getUserMFASettings, refreshSession } from './utils/auth/cognito'
import useGetCurrentVendiaUserQuery from './utils/hooks/use-current-vendia-user-query'
import useFeatureToggle, { Features } from './utils/hooks/use-feature-toggle'
import { useGetOrg } from './utils/hooks/use-get-org'
import { initSentry, setUser } from './utils/misc/sentry'
import { onRouteChange } from './utils/router/on-route-change'
import { selectedRoleState, showQueryDevToolsState, userState } from './utils/state'
import { Tier } from './utils/subscription'

const logger = debug('app:app.tsx')
const authLogger = debug('auth:main')
const upgradeLogger = debug('app:upgrade')

enum Status {
  INITIALIZED,
  TRIAL_EXPIRED,
}

function App() {
  // Open uni switcher, may graduate to full "cmd+k" menu one day...
  // "mod+k" is cross-platform (ctrl+k on Windows, cmd+k on Mac)
  useHotkeys(['mod+k'], () => {
    const uniSelect = document.getElementById(UNI_SELECT_ID)
    if (uniSelect) {
      uniSelect.click()
    }
  })

  const { isEnabled } = useFeatureToggle(Features.DEV_SETTINGS)
  const [showQueryDevTools, toggleShowQueryDevTools] = useRecoilState(showQueryDevToolsState)
  const [searchParams] = useSearchParams()

  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()

  const { setAuthStatus, setUnauthStatus, auth } = React.useContext(authContext)

  const [user, setUserState] = useRecoilState<any>(userState)
  const [selectedRole, setSelectedRole] = useRecoilState(selectedRoleState)

  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization

  const getUser = getCurrentVendiaUserQuery?.data?.getUser
  const roles = getUser?.roles as Role[]

  useEffect(() => {
    // Once getUser loads and roles are available, set the selected role
    // and fix issues with stale selected role name in localStorage
    const storedRoleName = localStorage.getItem(`selectedRoleName:${getUser?.userId}`)
    if (!selectedRole.name && roles && roles.length > 0) {
      logger('No selected role, but roles are loaded...')
      // If user selected a role in a previous session AND it still exists, set it as the selected role
      if (storedRoleName && roles.find((role) => role.name === storedRoleName)) {
        logger('Setting selected role to stored role name', storedRoleName)
        setSelectedRole({ name: storedRoleName })
      } else {
        // Otherwise, set the default role as the selected role
        const defaultRole = roles.find((role) => role.isDefault)
        logger('Setting selected role to default role:', defaultRole?.name ?? roles[0].name)
        setSelectedRole({ name: defaultRole?.name ?? roles[0].name })
        // Also, remove possibly stale role name from localStorage
        localStorage.removeItem(`selectedRoleName:${getUser?.userId}`)
      }
    }
  }, [roles, selectedRole, setSelectedRole, getUser?.userId])

  /* If user creates new account & runs through sign up flow in same browser instance */
  async function handleSignupLogoutRedirect(authInfo: typeof auth) {
    if (!authInfo.authenticated) {
      return
    }
    if (location.pathname === '/signup' && location.hash.startsWith('#code') && authInfo.authenticated) {
      // New account flow
      await Auth.signOut()
      setUnauthStatus()
      navigate(`${location.pathname}${location.hash}`)
    }
  }

  handleSignupLogoutRedirect(auth)

  /* Run effect once */
  useEffect(() => {
    /* initialize Sentry error tracking */
    initSentry({ dsn: import.meta.env.NEXT_PUBLIC_SENTRY })

    /* Load icon sprite once on app start */
    Icon.loadSprite()

    /* Listen to SPA route changes and track page views */
    onRouteChange((_newRoutePath) => {
      analytics.page()
    })
  }, [])

  /* Set user & auth context on auth changes and page reloads */
  useEffect(() => {
    onAuthChange()
  }, [auth.authenticated])

  useEffect(() => {
    if (!auth.emailVerified) {
      if (getOrg?.data?.getOrganization) {
        const org = getOrg?.data?.getOrganization
        const domain = auth.contactDetails?.unverified?.email?.split('@')[1]
        if (domain && org?.domains?.includes(domain)) {
          // Email should be verified
          const newContactDetails = {
            verified: {
              email: auth.contactDetails.unverified?.email,
              phoneNumber: auth.contactDetails.unverified?.phoneNumber,
            },
          }

          setAuthStatus({
            ...auth,
            emailVerified: true,
            contactDetails: newContactDetails,
          })

          auth.contactDetails = newContactDetails

          setUserState({
            ...auth,
            emailVerified: true,
            contactDetails: newContactDetails,
            sub: auth.userId,
          })
        }
      }
    }
  }, [auth, getOrg])

  async function onAuthChange() {
    try {
      const currentSession = getStoredUserAuth()

      if (!currentSession.authenticated) {
        setIsLoading(false)
        return
      }

      setUser(currentSession.userId.toString())

      setUserState({
        ...currentSession,
        sub: currentSession.userId,
      })

      /* Set __anon_id if its missing or different */
      const currentAnonId = analytics.storage.getItem('__anon_id')

      const idTokenPayload = decodeJwt(currentSession.idToken)

      const cognitoAnonId = idTokenPayload?.['custom:anonId'] as string | undefined
      if (cognitoAnonId && currentAnonId !== cognitoAnonId) {
        authLogger('Set __anon_id to value from cognito', cognitoAnonId)
        // @ts-expect-error AnalyticsInstance doesn't have setAnonymousId
        analytics.setAnonymousId(cognitoAnonId)
      }
      /* Set __user_id if its missing or different */
      const currentUserId = analytics.storage.getItem('__user_id')
      const cognitoUserId = idTokenPayload.sub as string
      if (cognitoUserId && currentUserId !== cognitoUserId) {
        authLogger('Set __user_id to value from cognito', currentUserId)
        analytics.storage.setItem('__user_id', cognitoUserId)
      }

      const [isVerified, contactDetails] = checkVerification(idTokenPayload)

      const mfaSettings = await getUserMFASettings(currentSession.accessToken.jwtToken)

      const timestamp = idTokenPayload['custom:timestamp'] as number | undefined
      setAuthStatus({
        userId: cognitoUserId,
        email: currentSession.email,
        type: currentSession.type,
        idToken: currentSession.idToken,
        accessToken: currentSession.accessToken,
        refreshToken: currentSession.refreshToken,
        timestamp: timestamp ?? 0,
        authenticated: true,
        emailVerified: isVerified,
        contactDetails: contactDetails,
        mfa: mfaSettings,
      })
    } catch (error: any) {
      authLogger(error)

      if (error === 'NotAuthorizedException' || error.name === 'NotAuthorizedException') {
        authLogger('<App /> loaded. NotAuthorizedException')
        const newSession = await refreshSession()
        setAuthStatus(newSession as AuthStatus)
      }

      if (error === 'No current user' || error.code === 'UserNotFoundException') {
        authLogger('<App /> loaded. No current user via onAuthChange')
        clearAuth()
        setUnauthStatus()
      }
    }
    setIsLoading(false)
  }

  //node path is /uni/*/details or /uni/*/settings or etc
  const isNodePath = location.pathname.match(/\/uni\/[^/]+\/[^/]+/)

  // Check to see if user is in expired trial and show paywall that blocks UI if so
  const [status, setStatus] = useState(Status.INITIALIZED)
  const subscriptionTier = getOrg?.data?.getOrganization?.subscription?.tier
  const subscriptionExpiry = getOrg?.data?.getOrganization?.subscription?.expiry
  useEffect(() => {
    const asyncUseEffect = async () => {
      if (!subscriptionTier || !subscriptionExpiry) {
        return
      }
      const expiryTime = Date.parse(subscriptionExpiry)
      const isExpired = !Number.isNaN(expiryTime) && Date.now() - expiryTime > 0

      if ((subscriptionTier === Tier.INDIVIDUAL_TRIAL || subscriptionTier === Tier.ENTERPRISE_TRIAL) && isExpired) {
        setStatus(Status.TRIAL_EXPIRED)
      } else {
        setStatus(Status.INITIALIZED)
        upgradeLogger('upgrade not needed, set to initialized')
      }
    }
    asyncUseEffect()
  }, [subscriptionTier])

  // Check to see if the account qualifies to upgrade to an Enterprise Org and we should notify the user
  // If so, show the Org onboarding modal
  const onboardingStatus = org?.onboardingStatus
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)
  useEffect(() => {
    if (onboardingStatus === OrgOnboardingStatus.OnboardingNotNotified) {
      setShowOnboardingModal(true)
    }
  }, [onboardingStatus])

  useEffect(() => {
    if (window.Cypress) {
      // @ts-ignore small hack to allow forcing data refresh in Cypress tests
      window.queryClient = queryClient
      queryClient.invalidateQueries
    }
  }, [queryClient])

  useEffect(() => {
    if (auth.authenticated && org) {
      if (import.meta.env.DEV) {
        return
      }
      const isEnabled = import.meta.env.NEXT_PUBLIC_VENDIA_ENV === 'production'
      if (isEnabled) {
        try {
          // @ts-expect-error globally available
          pendo.initialize({
            visitor: {
              id: auth.userId,
              email: auth.email,
              paidOrTrialUser: org.subscription?.tier,
            },
            account: {
              id: org.orgId,
              accountName: org.name,
              payingStatus: org.subscription?.tier,
            },
          })

          // @ts-expect-error globally available
          FS('setIdentity', {
            uid: auth.userId,
            properties: {
              email: auth.email,
              orgId: org.orgId,
              orgName: org.name,
              tier: org.subscription?.tier,
            },
          })

          // @ts-expect-error globally available
          window.hj('identify', auth.userId, {
            id: auth.userId,
            email: auth.email,
            trial: org.subscription?.tier,
          })
        } catch (e) {
          console.error('Error initializing analytics', e)
        }
      }
    }
  }, [auth, org])

  // Redirect to login if not authenticated or email not verified
  useEffect(() => {
    const redirectParam = searchParams.get('redirect')
    const redirectPath = redirectParam ?? location.pathname ?? '/'
    if (!auth.authenticated && !authRoutes.includes(location.pathname)) {
      navigate(`/login?redirect=${redirectPath}`)
    }
    if (Boolean(org?.orgId) && auth.authenticated && auth.type !== 'SSO' && auth.emailVerified === false) {
      navigate(`/verify-email?redirect=${redirectPath}`)
    }
  }, [auth, org?.orgId])

  return (
    !isLoading && (
      <div className='md:min-h-auto flex min-h-screen flex-col' data-testid='app'>
        <Outlet context={{ auth, isOrgLoaded: Boolean(org?.orgId) }} />

        <EnterpriseModal isTrialExpired isOpen={status === Status.TRIAL_EXPIRED} />
        <OrgOnboardingModal
          isOpen={showOnboardingModal}
          closeModal={() => {
            setShowOnboardingModal(false)
          }}
        />
        {isEnabled && showQueryDevTools && (
          <ReactQueryDevtoolsPanel
            style={{ position: 'absolute', width: '100%', left: 0, bottom: 0 }}
            onClose={() => toggleShowQueryDevTools(false)}
          />
        )}
      </div>
    )
  )
}

export default App
