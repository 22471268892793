import { useForm } from '@tanstack/react-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Organization } from '@vendia/management-api-types'
import inputValidation from 'src/utils/form/input.validation'
import { createOnBlurValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { refetchOrg } from 'src/utils/hooks/use-get-org'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import DevOnly from '../dev/dev-only'
import Form from '../fields/form'
import TextField from '../fields/text.field'
import Icon from '../icons/icon'
import BasicModal from '../modals/modal.basic'
import { logger, ModalState, OrgModalInner, OrgOnboardingStep } from './org-onboarding-modal'

export function StepOrgName({
  org,
  setModalState,
  dismissModal,
}: {
  org?: Organization
  setModalState: (state: Partial<ModalState>) => void
  dismissModal: () => void
}) {
  const api = useApi()
  const queryClient = useQueryClient()

  const defaultValues = {
    name: org?.name,
  }

  const form = useForm({
    defaultValues,
    onSubmit: async ({ value }: any) => {
      const response = await mutation.mutateAsync(value)
      if (response?.errors?.length) {
        notify.error(response.errors[0].message)
        return
      }
      setModalState({ step: OrgOnboardingStep.SET_LOGO })

      // Refresh org
      await new Promise((resolve) => setTimeout(resolve, 1000))
      refetchOrg({ queryClient })
    },
  })

  interface Response {
    updateOrgProfile: Organization
    errors: {
      message: string
    }[]
  }

  const mutation = useMutation<Response, Error, typeof defaultValues>({ mutationFn: api.updateOrgProfile.bind(api) })

  return (
    <>
      <OrgModalInner>
        <Form id='edit-org-name' form={form}>
          <div className='mb-8 flex flex-col gap-12'>
            <p>First, let's give your Organization a name. Typically, this will be the name of your company.</p>
            <div className='j flex w-full justify-center gap-4'>
              <TextField
                name='name'
                label='Organization name'
                placeholder='Awesome Company, Inc.'
                form={form}
                data-testid='org-name-input'
                validators={createOnBlurValidator(inputValidation.isGenericNonEmptyString, true)}
              />
            </div>
          </div>
        </Form>
      </OrgModalInner>
      <BasicModal.Footer>
        <div className='flex gap-2'>
          <Button kind='secondary' size={'small'} className='w-36' onClick={dismissModal}>
            Cancel
          </Button>
          <DevOnly>
            <Button
              kind='secondary'
              size={'small'}
              className='w-36'
              onClick={() => setModalState({ step: OrgOnboardingStep.INTRO })}
            >
              Previous
            </Button>
          </DevOnly>
          <form.Subscribe selector={(state) => [state.isSubmitting, state.isFieldsValid]}>
            {([isSubmitting, isFieldsValid]) => (
              <Button
                kind='primary'
                size={'small'}
                className='w-36'
                disabled={!isFieldsValid || isSubmitting}
                type='submit'
                form='edit-org-name'
              >
                {isSubmitting && <Icon className={'mr-2'} isSpinning name='refresh' size={'xs'} />}
                Next
              </Button>
            )}
          </form.Subscribe>
        </div>
      </BasicModal.Footer>
    </>
  )
}
