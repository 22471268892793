import MultiListboxInput, { MultiListboxInputProps } from '../inputs/multi-listbox.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, onChangeHandler, useRemoveFieldOnUnmount } from './utils'

export type MultiListboxFieldProps = MultiListboxInputProps & FormFieldProps<string[]>

const MultiListboxField = ({
  form,
  name,
  wrapperClassName,
  labelClassName,
  descriptionClassName,
  validators,
  isArrayField,
  arrayFieldName,
  arrayFieldIndex,
  defaultValue,
  onChange,
  onBlur,
  removeFieldOnUnmount,
  ...rest
}: MultiListboxFieldProps) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      labelClassName={labelClassName}
      descriptionClassName={descriptionClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      {...rest}
    >
      <form.Field name={name} validators={validators} defaultValue={defaultValue}>
        {(field) => (
          <MultiListboxInput
            {...rest}
            name={name}
            value={field.state.value}
            hasError={field.state.meta.errors?.length > 0}
            onBlur={onBlurHandler(field, onBlur)}
            onChange={onChangeHandler(field, onChange)}
          />
        )}
      </form.Field>
    </FieldSet>
  )
}

export default MultiListboxField
