import { useMutation } from '@tanstack/react-query'
import { LakehouseSnowflakeConnectionInput, SnowflakeInfo } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { SnowflakeConnection } from 'src/components/lakehouse/snowflake-connection'
import { UniCreateStepValues } from 'src/types/lakehouse'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:Snowflake')

export const StepConnectSnowflake: StepComponent<UniCreateStepValues> = (props) => {
  const { stepValues, setCurrStepId, setStepValues, currStepIndex, submitStep } = props
  const navigate = useNavigate()
  const api = useApi()
  const getTablesMutation = useMutation({
    mutationFn: (connectionInput: LakehouseSnowflakeConnectionInput) =>
      api.getSnowflakeInfo<{
        testLakehouseSnowflakeConnection?: SnowflakeInfo
      }>({ connectionInput }),
    onError: (error) => notify.error(`Error testing Snowflake connection: ${error}`),
    onSuccess: (response) => {
      logger('response', response)
      if (response.message) {
        notify.error(`Error testing Snowflake connection: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.testLakehouseSnowflakeConnection) {
        notify.error('Error testing Snowflake connection')
        return
      }
      setStepValues({
        ...stepValues,
        sourceRegion: response.testLakehouseSnowflakeConnection.region,
        availableTables: response.testLakehouseSnowflakeConnection.tables as string[],
      })
      setCurrStepId(StepId.SelectSnowflakeTable)
    },
  })

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Connect to Snowflake'
        description={'Let’s authenticate your Snowflake account'}
      />
      <ScrollableStepContent verticallyCentered>
        {/* @ts-ignore tricky type problem */}
        <SnowflakeConnection {...props} />
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          kind='secondary'
          className='w-28'
          // onClick={() => setSearchParams({ flow: CreateFlow.SELECT_TYPE })}
          onClick={() => submitStep({ nextStepIndex: currStepIndex - 1 })}
          data-testid='back-button'
        >
          Back
        </Button>
        <Button
          kind='primary'
          className='w-28'
          icon={getTablesMutation.isPending ? 'refresh' : null}
          iconProps={{
            isSpinning: getTablesMutation.isPending,
          }}
          disabled={getTablesMutation.isPending}
          onClick={async () => {
            // Stay on same step, validate form
            const latestStepValues = await submitStep({ nextStepIndex: currStepIndex })
            if (latestStepValues !== null) {
              const connectionInput = {
                authType: 'PASSWORD',
                account: latestStepValues.snowflakeHost,
                username: latestStepValues.snowflakeUsername,
                password: latestStepValues.snowflakePassword,
                warehouse: latestStepValues.snowflakeWarehouse,
                database: latestStepValues.snowflakeDatabase,
                schema: latestStepValues.snowflakeSchema,
                role: latestStepValues.snowflakeRole,
              }
              logger('connectionInput', connectionInput)
              getTablesMutation.mutate(connectionInput)
            }
          }}
          data-testid='next-button'
        >
          Next
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
