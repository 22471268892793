import { useMutation } from '@tanstack/react-query'
import {
  CspLowerEnum,
  DataPolicyColumnEffect,
  LakehouseAppInput,
  LakehouseDataProductInput,
  LakehouseDataSourceInput,
  LakehouseDataSourceTypeEnum,
  LakehouseSyncScheduleEnum,
} from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import Button from 'src/components/buttons/button'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { EditDataProducts } from 'src/components/lakehouse/edit-data-products'
import { useDevSettings } from 'src/pages/developer-settings.page'
import { FormLakehouseDataProduct, UniCreateStepValues } from 'src/types/lakehouse'
import useApi from 'src/utils/hooks/use-api'
import notify from 'src/utils/notify'
import { generateShortId } from 'src/utils/short-id'
import { userState } from 'src/utils/state'
import { validateSchemaAndDisplayErrors } from 'src/utils/validate-schema'

import { DEFAULT_REGION, SOURCE_NODE_NAME } from '../config'
import { buildUniFqn, generateNodesForApi, snowflakeToAwsRegionMap } from '../utils'

const logger = debug('app:CreateDataProducts')

export const CreateDataProductsStep: StepComponent<UniCreateStepValues> = (props) => {
  const { devSettings } = useDevSettings()
  const userData = useRecoilValue<any>(userState)
  const { setCurrStepIndex, currStepIndex, submitStep, form } = props
  const api = useApi()
  const navigate = useNavigate()

  const createLakehouseMutation = useMutation({
    mutationFn: (input: LakehouseAppInput) => api.createLakehouseApp<{ createLakehouseApp?: string }>({ input }),
    onError: (error) => {
      notify.error(`Error creating Uni: ${error}`)
    },
    onSuccess: (response) => {
      logger('response', response)
      if (response.message) {
        notify.error(`Error creating Uni: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.createLakehouseApp) {
        notify.error('Error creating Uni')
        return
      }
      // TODO: better success state
      notify.success('Uni created successfully')
      navigate('/')
    },
  })

  const hasProductThatReturnsNoData = () =>
    form.state.values.products?.some(
      (product: any) =>
        product?.defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude && product?.columnPolicies.length === 0,
    )

  const createLakehouse = async () => {
    // Stay on same step, validate form
    const latestStepValues = await submitStep({ nextStepIndex: currStepIndex })
    if (!latestStepValues) {
      return
    }
    const {
      schema,
      selectedTable,
      sourceRegion,
      selectedTableColumns,
      alias,
      snowflakeHost,
      snowflakeUsername,
      snowflakePassword,
      snowflakeSchema,
      snowflakeRole,
      snowflakeDatabase,
      snowflakeWarehouse,
      products,
    } = latestStepValues

    // This SHOULD be valid, but let's check once more here
    const [e, schemaJson] = await validateSchemaAndDisplayErrors(schema)
    if (e) {
      return
    }

    function distributionNodeNameFromRegion(region: string) {
      return `distribution-node-${region}`
    }
    // Grab regions from data products, dedupe
    const uniqueRegions = [...new Set(products.flatMap((product) => product.regions))]
    // Create a simple node config (similar to config we build in StepAddNodes) — one node for each region!
    type Node = { name: string; description?: string; csp: CspLowerEnum; region: string }
    const productNodes: Node[] = uniqueRegions.map((region: string) => ({
      name: distributionNodeNameFromRegion(region),
      csp: CspLowerEnum.Aws,
      region,
    }))
    // We also need one node for the "source" which pulls data from Snowflake
    const sourceNode: Node = {
      name: SOURCE_NODE_NAME,
      csp: CspLowerEnum.Aws,
      // Set source region to match snowflake region if there's a match with a region we support
      region: snowflakeToAwsRegionMap[sourceRegion] ?? DEFAULT_REGION,
    }
    // Then format with extra details just like we do in standard create uni flow
    const nodesInput = generateNodesForApi(userData?.email, [sourceNode, ...productNodes])

    // Keep track of shortIds to avoid collisions
    const existingIds: string[] = []
    // Build full product input from form data
    const productsInput: LakehouseDataProductInput[] = products.map((product: FormLakehouseDataProduct) => {
      const shortId = generateShortId({ existingIds })
      existingIds.push(shortId)
      return {
        key: shortId,
        name: product.name,
        syncSchedule: LakehouseSyncScheduleEnum.Daily,
        // TODO: deprecate regions in favor of nodes once back-end switches over
        regions: product.regions,
        nodes: product.regions.map(distributionNodeNameFromRegion),
        tableDefinitions: [
          {
            tableName: selectedTable,
            columnPolicies: product.columnPolicies,
            rowPolicies: product.rowPolicies,
            defaultColumnPolicyEffect: product.defaultColumnPolicyEffect,
          },
        ],
      }
    })

    let source: LakehouseDataSourceInput | undefined = undefined
    if (snowflakeHost) {
      source = {
        type: LakehouseDataSourceTypeEnum.Snowflake,
        snowflakeConnection: {
          authType: 'PASSWORD',
          account: snowflakeHost,
          username: snowflakeUsername,
          password: snowflakePassword,
          warehouse: snowflakeWarehouse,
          database: snowflakeDatabase,
          schema: snowflakeSchema,
          role: snowflakeRole,
        },
        tableDefinitions: [
          {
            tableName: selectedTable,
            columns: selectedTableColumns,
          },
        ],
      }
    } else if (latestStepValues.clouderaJdbcUrl) {
      source = {
        type: LakehouseDataSourceTypeEnum.Cloudera,
        clouderaConnection: {
          jdbcUrl: latestStepValues.clouderaJdbcUrl,
          atlasUrl: latestStepValues.clouderaAtlasUrl,
          username: latestStepValues.clouderaUsername,
          password: latestStepValues.clouderaPassword,
          database: latestStepValues.clouderaDatabase,
        },
        tableDefinitions: [
          {
            tableName: selectedTable,
            columns: selectedTableColumns,
          },
        ],
      }
    }

    if (source) {
      const input: LakehouseAppInput = {
        name: alias,
        schema,
        nodes: nodesInput,
        sources: [source],
        products: productsInput,
        shareAppVersion: devSettings?.shareAppVersion ?? undefined,
      }
      createLakehouseMutation.mutate(input)
    } else {
      notify.error('No source defined')
    }
  }

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Configure your data products'
        description={
          'Name your data products and select the regions of the world in which you’d like to share them. You can create multiple products and locations with specific sharing permissions.'
        }
      />
      <ScrollableStepContent>
        <div className='w-full max-w-7xl'>
          <div className='flex w-full flex-col gap-4'>
            {/* @ts-ignore tricky type problem */}
            <EditDataProducts {...props} />
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='tertiary' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
        <Button
          kind='secondary'
          className='w-28'
          onClick={() => setCurrStepIndex(currStepIndex - 1)}
          data-testid='back-button'
        >
          Back
        </Button>
        <Button
          kind='primary'
          className='w-28'
          icon={createLakehouseMutation.isPending ? 'refresh' : null}
          iconProps={{
            isSpinning: createLakehouseMutation.isPending,
          }}
          disabled={
            createLakehouseMutation.isPending ||
            (form.state.isDirty && !form.state.isValid) ||
            hasProductThatReturnsNoData()
          }
          onClick={createLakehouse}
          data-testid='next-button'
        >
          Create
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
