import React from 'react'

import MonacoEditorInput, { MonacoEditorInputProps } from '../inputs/monaco-editor.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { useRemoveFieldOnUnmount } from './utils'

export type MonacoEditorFieldProps = MonacoEditorInputProps & FormFieldProps

const MonacoEditorField = ({
  id,
  form,
  name,
  validators,
  language,
  minHeight,
  defaultValue,
  className,
  onChange,
  onBlur,
  removeFieldOnUnmount,
  ...rest
}: MonacoEditorFieldProps) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet name={name} form={form} {...rest}>
      <form.Field name={name} validators={validators} defaultValue={defaultValue}>
        {(field) => (
          <MonacoEditorInput
            {...rest}
            id={id}
            value={field.state.value}
            hasError={field.state.meta.errors?.length > 0}
            language={language}
            minHeight={minHeight}
            className={className}
            onChange={(value, editor) => {
              if (onChange) onChange(value, editor)
              field.handleChange(value)
            }}
          />
        )}
      </form.Field>
    </FieldSet>
  )
}

export default MonacoEditorField
