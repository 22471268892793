import Papa from 'papaparse'
import { useContext, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Balancer from 'react-wrap-balancer'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import { Dataset } from 'src/types/schema'
import { assert } from 'src/utils/assert'
import notify from 'src/utils/notify'

import { logger } from '../flow-standard/schema-designer.step'
import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'
import { createArrayOfObjectsEntityObjectDefinitionPath, getSchemaKeyFromName, getTypeFromDatasetValues } from './utils'

export const EmptyEntityView = () => {
  const {
    designerState: { dataset },
    setDesignerState,
    schema,
    selectedEntityKey,
    selectedEntity,
    isUnsupportedEntityType,
    readOnlyMode,
    upsertField,
    status,
    selectedFieldParentPath,
  } = useContext(SchemaDesignerContext) as SchemaDesignerContextType
  assert(schema && selectedEntityKey && selectedEntity, 'schema, selectedEntityKey and selectedEntity must exist')

  useEffect(() => {
    if (dataset && selectedFieldParentPath && status !== Status.SHOW_PARSED_FILE_MODAL) {
      logger('upserting from dataset!', dataset)
      const firstRow = dataset?.[0] ?? {}
      Object.keys(firstRow).forEach((key) => {
        upsertField({
          title: key,
          type: getTypeFromDatasetValues(dataset, key),
          required: true,
        })
      })
      setDesignerState({
        dataset: null,
        selectedFieldParentPath: null,
      })
    }
  }, [selectedFieldParentPath, upsertField, dataset, status, setDesignerState])

  const onDrop = async (acceptedFiles: any[], rejectedFiles: any[]) => {
    logger('onDrop fired')
    if (acceptedFiles.length === 0) {
      logger('no files accepted')
      return
    }
    if (rejectedFiles.length > 0) {
      notify.error(`Encountered an error trying to read this file. ${rejectedFiles[0]?.errors?.[0].message ?? ''}`)
    }
    const parsedDataset = await new Promise<Dataset>((resolve) => {
      Papa.parse(acceptedFiles[0], {
        complete: (results) => {
          resolve(results.data as Dataset)
        },
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
      })
    })
    logger('parsedDataset', parsedDataset)

    // Validate that the dataset has at least one row
    if (parsedDataset.length === 0 || Object.keys(parsedDataset[0]).length === 0) {
      notify.error('This file does not contain any data.')
      return
    }

    // upsertField will convert each title to a valid JSON schema key, but
    // we need to make sure there aren't any duplicate keys (unlikely, but possible)
    const firstRow = parsedDataset?.[0]
    const fieldKeyToRowValueMap: Record<string, any> = {}
    let duplicateKeyError = ''
    Object.keys(firstRow).forEach((rowValue) => {
      const fieldKey = getSchemaKeyFromName({ name: rowValue })
      if (fieldKeyToRowValueMap[fieldKey]) {
        // TODO: this error message sucks but it's unlikely to happen, should let them pick an alternate key instead (or add a random suffix?)
        duplicateKeyError = `Encountered a problem generating entity schema from this file. The following columns will generate duplicate schema attribute keys: ${fieldKeyToRowValueMap[fieldKey]} and ${rowValue}. Both will generate a schema key of ${fieldKey}. Please rename one of these columns and try again.`
      } else {
        fieldKeyToRowValueMap[fieldKey] = rowValue
      }
    })
    if (duplicateKeyError) {
      notify.error(duplicateKeyError)
      return
    }

    // Let them select their unique identifier
    setDesignerState({
      status: Status.SHOW_PARSED_FILE_MODAL,
      dataset: parsedDataset,
      selectedFieldParentPath: createArrayOfObjectsEntityObjectDefinitionPath(selectedEntityKey),
    })
  }

  const dropzone = useDropzone({
    accept: 'text/csv',
    maxSize: 5 * 1024 * 1024,
    maxFiles: 1,
    onDrop: onDrop,
  })

  if (isUnsupportedEntityType) {
    const typeDetail =
      selectedEntity.type === 'array'
        ? `an array of ${selectedEntity?.items?.type}s`
        : `a singular ${selectedEntity.type}`
    return (
      <div className='flex w-full justify-center'>
        <p className='text-neutral-8 max-w-4xl p-4 pt-12 text-center text-xl font-normal'>
          <Balancer>
            This type of entity ({typeDetail}) isn't supported by our visual schema designer.
            <br />
            <br />
            {readOnlyMode ? (
              <>
                You can view the JSON schema for this entity by clicking <strong>View as JSON schema</strong> above.
              </>
            ) : (
              <>
                You can edit the JSON schema for this entity by clicking <strong>Use JSON editor</strong> above.
              </>
            )}
          </Balancer>
        </p>
      </div>
    )
  }

  return (
    <div className='w-full'>
      <div className='flex flex-col gap-6'>
        <Card onWhiteBg>
          <div className=''>
            <h3 className='m-0 mb-4 text-xl font-semibold'>
              Start with CSV<span className='!font-normal'> - Recommended</span>
            </h3>
            <p className='mb-4 text-sm'>
              You can quickly add multiple attributes at once by uploading a CSV file that you currently use.
            </p>
            <hr />
            <div className='mt-4 flex gap-8'>
              <div className='w-1/2'>
                <p className='mb-1 text-sm font-bold'>Before uploading</p>
                <p className='text-sm'>
                  <Balancer>
                    Export a CSV from your data source that only includes columns you want to use in the data model for{' '}
                    <span className='font-bold'>
                      {schema.properties[selectedEntityKey]?.title || selectedEntityKey}
                    </span>
                    . It can include multiple rows of sample data.
                  </Balancer>
                </p>
              </div>
              <div className='flex w-1/2'>
                <div
                  {...dropzone.getRootProps()}
                  className='border-purple-1300 bg-uibg-0 flex w-full items-center justify-center rounded-lg border-2 border-dashed p-6'
                >
                  <div className='flex flex-col items-center'>
                    <div className='mb-2 w-16'>
                      <img src='/images/upload-cloud.svg' alt='Upload file' />
                    </div>
                    <input {...dropzone.getInputProps()} />
                    {/* Somehow this works perfectly when these labels target an input id that doesn't exist..? */}
                    {/* Adding an id to the input causes a bug where onDrop is not called the 2nd time a file is dropped */}
                    {/* https://github.com/react-dropzone/react-dropzone/issues/1097 */}
                    <label htmlFor={'csv-file-input'} className='text-center'>
                      Drag and drop your .csv file here
                    </label>
                    <span className='my-2 uppercase'>or</span>
                    <label
                      htmlFor={'csv-file-input'}
                      className='bg-primaryCore hover:bg-primaryCoreLight cursor-pointer rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm'
                    >
                      Upload file
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card onWhiteBg>
          <div className='flex items-center justify-between'>
            <div>
              <h3 className='m-0 mb-4 text-xl font-bold'>Start from scratch</h3>
              <p className='text-sm'>Configure attributes one by one using our editor.</p>
            </div>
            <div>
              <Button
                kind='secondary'
                onClick={() => {
                  setDesignerState({
                    status: Status.SHOW_FIELD_MODAL_NEW,
                    selectedFieldParentPath: createArrayOfObjectsEntityObjectDefinitionPath(selectedEntityKey),
                  })
                }}
                icon='plus-m'
                iconSize={14}
              >
                Add attribute
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
