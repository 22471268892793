import { useForm } from '@tanstack/react-form'
import { useContext } from 'react'
import Button from 'src/components/buttons/button'
import Form from 'src/components/fields/form'
import ListboxField from 'src/components/fields/listbox.field'
import BasicModal from 'src/components/modals/modal.basic'
import { isRequiredOnBlur } from 'src/utils/form/validation'

import { logger } from '../flow-standard/schema-designer.step'
import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'
import { getTypeFromDatasetValues } from './utils'

export function ParsedFileModal({ title, isOpen }: { title: string; isOpen: boolean }) {
  const { designerState, setDesignerState, upsertField } = useContext(
    SchemaDesignerContext,
  ) as SchemaDesignerContextType

  const form = useForm<any>({
    defaultValues: {
      identifier: null,
    },
    onSubmit: async ({ value }) => {
      logger('formValues', value)
      const dataset = designerState.dataset
      const firstRow = dataset?.[0] ?? {}
      const identifier = value.identifier
      Object.keys(firstRow).forEach((key) => {
        upsertField({
          title: key,
          type: getTypeFromDatasetValues(dataset!, key),
          isUnique: key === identifier,
          required: true,
        })
      })
      form.reset()
      onClose()
    },
  })

  const onClose = () => {
    form.reset()
    setDesignerState({ status: Status.IDLE, dataset: null, selectedFieldParentPath: null, selectedFieldKey: null })
  }

  const identifierOptions = Object.keys(designerState.dataset?.[0] ?? {})
    .map((key) => ({ label: key, value: key }))
    .filter((option) => option.value !== 'id' && option.value !== '_id')

  return (
    <BasicModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      buttons={[
        <Button
          key='cancel'
          kind='secondary'
          onClick={() => {
            logger('cancel clicked')
            onClose()
          }}
        >
          Cancel
        </Button>,
        <Button key='save' kind='primary' type='submit' form='parsed-file-modal-form' disabled={!form.state.isValid}>
          Done
        </Button>,
      ]}
    >
      <Form id='parsed-file-modal-form' className='flex flex-col gap-4' form={form}>
        <div className='flex flex-col gap-4'>
          <div className='mb-12 mt-8'>
            <ListboxField
              name='identifier'
              label='Select your unique identifier'
              description='Ex. if your entity was "Car", you might choose "VIN" as the unique identifier.'
              options={identifierOptions}
              form={form}
              validators={isRequiredOnBlur}
            />
          </div>
        </div>
      </Form>
    </BasicModal>
  )
}
