import { ShareApp, Uni } from '@vendia/management-api-types'
import { useMemo } from 'react'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import { parsetLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { FormLakehouseDataProduct, LakehouseEditStepValues } from 'src/types/lakehouse'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'

import { StepId } from './config'
import { StepDataProductsEdit } from './data-products-edit.step'
import { LakehouseDataLoader } from './lakehouse-data-loader'
import { StepSharingPermissionsEdit } from './sharing-permissions-edit.step'

export const PageEditDataProducts = () => <LakehouseDataLoader Comp={PageEditDataProductsContent} />

export const PageEditDataProductsContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parsetLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )
  // Single source for now & single table for now!
  const sourceConfig = shareAppConfig.sources[0]
  const sourceTable = sourceConfig.tableDefinitions[0]
  const selectedTableColumns = sourceTable.columns

  const products: FormLakehouseDataProduct[] = shareAppConfig.products.map(
    (product) =>
      ({
        name: product.name,
        regions: product.regions,
        // Assuming single table for now here too!
        columnPolicies: product.tableDefinitions[0].columnPolicies,
        rowPolicies: product.tableDefinitions[0].rowPolicies,
      }) as FormLakehouseDataProduct,
  )

  return (
    <MultiStepFlow<LakehouseEditStepValues>
      initialStepValues={{
        products,
        sharingPermissionsEditIndex: 0,
        selectedTableColumns,
      }}
      stepConfig={[
        {
          id: StepId.DataProducts,
          StepComponent: StepDataProductsEdit,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepSharingPermissionsEdit,
        },
      ]}
      hideStepNav
    />
  )
}
