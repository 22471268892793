import { FormLakehouseDataProduct, LakehouseEditStepValues, UniCreateStepValues } from 'src/types/lakehouse'
import { setValue } from 'src/utils/form/form'

import { AWS_REGIONS } from '../csp/regions'

export function formatListAsPhrase(items: string[] = []): string {
  switch (items.length) {
    case 0:
      return ''
    case 1:
      return items[0]
    case 2:
      return `${items[0]} and ${items[1]}`
    default:
      return `${items.slice(0, -1).join(', ')}, and ${items[items.length - 1]}`
  }
}

export function getPrettyProductRegions(productValues: any): string {
  const productRegions: string[] = productValues?.regions
  const prettyRegions = productRegions?.map((region: string) => AWS_REGIONS.find((r) => r.value === region)!.label)
  return formatListAsPhrase(prettyRegions)
}

export function getCurrentProduct({ products, sharingPermissionsEditIndex }: Partial<UniCreateStepValues>) {
  return products?.[sharingPermissionsEditIndex!]
}

export function getCurrentProductFieldValue<T>(
  { products, sharingPermissionsEditIndex }: LakehouseEditStepValues,
  field: keyof FormLakehouseDataProduct,
): T {
  const product = getCurrentProduct({ products, sharingPermissionsEditIndex })
  return product?.[field] as T
}

export function setCurrentProductFieldValue(
  stepValues: LakehouseEditStepValues,
  field: keyof FormLakehouseDataProduct,
  value: any,
) {
  console.log('stepValues', stepValues, 'field', field, 'value', value)
  return setValue(stepValues, `products[${stepValues.sharingPermissionsEditIndex}].${field}`, value)
}
