import { FormApi, ReactFormApi } from '@tanstack/react-form'
import React, { useEffect, useState } from 'react'
import { getRefData } from 'src/utils/analytics/utils/get-ref-data'

import TextField from '../fields/text.field'

/* Get additonal fields needed for marketing */
export default function MarketingFields({ form }: { form: FormApi<any> & ReactFormApi<any> }) {
  const [values, setValues] = useState<string[]>([])
  useEffect(() => {
    async function getValues() {
      const vlx = await Promise.all([
        getRefData('_first.type'),
        getRefData('_first.date'),
        getRefData('_first.entry.url'),
      ])
      setValues(vlx)
    }
    getValues()
  }, [])

  if (!values.length) return null

  return (
    <>
      <TextField form={form} name='custom_first_visitor_source' type='hidden' defaultValue={values.at(0)} />
      <TextField form={form} name='custom_first_visitor_date' type='hidden' defaultValue={values.at(1)} />
      <TextField form={form} name='custom_first_visitor_entry' type='hidden' defaultValue={values.at(2)} />
    </>
  )
}
