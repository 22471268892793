import { ForwardedRef, forwardRef } from 'react'

import TextAreaInput, { TextAreaInputProps } from '../inputs/text-area.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, onChangeHandler, useRemoveFieldOnUnmount } from './utils'

export type TextAreaFieldProps = TextAreaInputProps &
  FormFieldProps & {
    rows?: number
  }

const TextAreaField = (
  {
    form,
    name,
    wrapperClassName,
    labelClassName,
    descriptionClassName,
    validators,
    isArrayField,
    arrayFieldName,
    arrayFieldIndex,
    defaultValue,
    onBlur,
    onChange,
    removeFieldOnUnmount,
    ...rest
  }: TextAreaFieldProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      labelClassName={labelClassName}
      descriptionClassName={descriptionClassName}
      {...rest}
    >
      <form.Field name={name} validators={validators} defaultValue={defaultValue}>
        {(field) => (
          <TextAreaInput
            {...rest}
            ref={ref}
            name={name}
            value={field.state.value}
            hasError={field.state.meta.errors?.length > 0}
            onBlur={onBlurHandler(field, onBlur)}
            onChange={onChangeHandler(field, onChange)}
          />
        )}
      </form.Field>
    </FieldSet>
  )
}

export default forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(TextAreaField)
