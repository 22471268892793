// @ts-strict-ignore
import $RefParser, { JSONSchema } from '@apidevtools/json-schema-ref-parser'
import debug from 'debug'
import { VendiaSchema } from 'src/types/schema'
import notify from 'src/utils/notify'

const logger = debug('app:validate-schema')

type ValidationResult = [Error | null, VendiaSchema | null]

type Json = string | number | boolean | null | Json[] | { [key: string]: Json }

function isObject(value: Json): boolean {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export const validateSchema = async (schema: any): Promise<ValidationResult> => {
  let schemaJson: VendiaSchema
  try {
    schemaJson = JSON.parse(schema)

    await $RefParser.dereference(schemaJson as JSONSchema, { dereference: { circular: false } })

    const errorsArray = validateIndexes(schemaJson)
    if (errorsArray.length) {
      throw new Error(`Indexes are malformed. Please review the following error(s): ${errorsArray.join('\n')}`)
    }

    const aclErrorsArray = validateAcls(schemaJson)
    if (aclErrorsArray.length) {
      throw new Error(`ACLs are malformed. Please review the following error(s): ${aclErrorsArray.join('\n')}`)
    }

    // TODO: should we error for very deeply nested arrays that schema designer doesn't support?
    // Probably just handle that in schema designer react code...

    validateJson(schemaJson as unknown as Json, [], (pathToValue, value) => {
      // Validation for objects - why doesn't typescript like isObject for narrowing here?
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        // validate "type" is always a string IF the object is a definition of an object property
        // Example path: ['properties', 'Order', 'items', 'properties', 'retailerWarehouseCode']
        // pathToValue.at(-2) will be "properties" in this case, so retailerWarehouseCode must have a "type" with a string value
        if (pathToValue.at(-2) === 'properties' && typeof value.type !== 'string') {
          throw new Error(
            `Invalid JSON schema at ${pathToValue.join('.')}. Expected "type" to be a string, got ${betterTypeOf(
              value.type,
            )}.`,
          )
        }

        // validate "items" is always an object, not an array of definitions
        if (value.type === 'array' && !isObject(value.items)) {
          throw new Error(
            `Invalid JSON schema at ${pathToValue.join('.')}. Expected "items" to be an object, got ${betterTypeOf(
              value.items,
            )}.`,
          )
        }

        // validate "properties" exists for objects and value is an object
        if (value.type === 'object' && value.properties && !isObject(value.properties)) {
          throw new Error(
            `Invalid JSON schema at ${pathToValue.join('.')}. Expected "properties" to be an object, got ${betterTypeOf(
              value.properties,
            )}.`,
          )
        }

        // validate "properties" must have at least one property to generate a valid GraphQL schema
        if (value.type === 'object' && value.properties && Object.keys(value.properties).length === 0) {
          throw new Error(
            `Invalid JSON schema at ${pathToValue.join('.')}. Expected "properties" to have at least one property.`,
          )
        }
      }
    })
  } catch (e) {
    return [e, null]
  }
  return [null, schemaJson]
}

export const validateSchemaAndDisplayErrors = async (schema: any): Promise<ValidationResult> => {
  const [error, schemaJson] = await validateSchema(schema)

  if (error) {
    // Show error notification
    if (error.message.includes('Circular $ref pointer found at')) {
      // Fix for error message including webpage URL in JSON schema path to circular reference
      const relevantPart = error?.message?.split('uni/create#/')?.[1]
      const errorMessage = relevantPart ? `Circular $ref pointer found at ${relevantPart}` : error.message
      notify.error(
        `Circular references are not currently supported in Uni JSON schema.
       <br/>Please see the error message below for more details: 
       <br/>${errorMessage}`,
      )
    } else {
      notify.error(`Malformed JSON schema. Error details:<br/>
      ${error.message}
      <br/>
      <br/>Syntax errors are highlighted in red below. Please correct all syntax errors before proceeding.
      `)
    }
    // Then throw to allow caller to handle error state as well
    throw error
  }
  return [error, schemaJson]
}

const validateAcls = (schema: VendiaSchema): string[] => {
  const acls = schema['x-vendia-acls'] ?? {}
  if (typeof acls !== 'object') {
    return [`Invalid ACLs format ("x-vendia-acls"). Expected object, got ${betterTypeOf(acls)}.`]
  }
  const errors = Object.entries(acls).reduce<string[]>((memo, [aclName, aclDef]) => {
    if (typeof aclDef !== 'object') {
      memo.push(`Invalid ACL format ("x-vendia-acls", "${aclName}"). Expected object, got ${betterTypeOf(aclDef)}.`)
      return
    }
    if (typeof aclDef.type !== 'string') {
      memo.push(
        `Invalid ACL format ("x-vendia-acls", "${aclName}"). Expected the key "type" with a string value, got ${betterTypeOf(
          aclDef.type,
        )}.`,
      )
    }
    Object.keys(aclDef).forEach((key) => {
      if (key !== 'type') {
        memo.push(
          `Invalid ACL format ("x-vendia-acls", "${aclName}"). Unexpected key "${key}". The only valid key is "type".`,
        )
      }
    })
    return memo
  }, [])
  return errors
}

export const validateIndexes = (schema: VendiaSchema): string[] => {
  const indexes = schema['x-vendia-indexes'] ?? {}
  if (typeof indexes !== 'object') {
    return [`Invalid indexes format ("x-vendia-indexes"). Expected object, got ${betterTypeOf(indexes)}.`]
  }
  const errors = Object.entries(indexes).reduce<string[]>((memo, [indexName, indexArray]) => {
    if (!Array.isArray(indexArray)) {
      memo.push(
        `Invalid index format ("x-vendia-indexes", "${indexName}"). Expected array, got ${betterTypeOf(indexArray)}.`,
      )
      return memo
    }
    indexArray.forEach((indexDef) => {
      if (typeof indexDef !== 'object') {
        memo.push(
          `Invalid index format ("x-vendia-indexes", "${indexName}"). Expected object, got ${betterTypeOf(indexDef)}.`,
        )
        return
      }
      if (typeof indexDef.type !== 'string') {
        memo.push(
          `Invalid index format ("x-vendia-indexes", "${indexName}"). Expected the key "type" with a string value, got ${betterTypeOf(
            indexDef.type,
          )}.`,
        )
      }
      if (typeof indexDef.property !== 'string') {
        memo.push(
          `Invalid index format ("x-vendia-indexes", "${indexName}"). Expected the key "property" with a string value, got ${betterTypeOf(
            indexDef.property,
          )}.`,
        )
      }
    })
    return memo
  }, [])
  return errors
}

// Validate a JSON object against a custom validator function
// - Can pass in a validator function to validate each value
// - Can throw an error if the validator returns false
// - Returns a flattened version of JSON too with keys as JSON paths!
export function validateJson(
  jsonObj: Json,
  path: string[] = [],
  validator?: (currentPath: string[], value: Json) => boolean | void,
): { [key: string]: Json } {
  if (Array.isArray(jsonObj)) {
    return jsonObj.reduce<{ [key: string]: Json }>((acc, item, index) => {
      const itemPath = [...path, index.toString()]
      if (validator && validator(itemPath, item) === false) {
        throw new Error(`Failed condition at path ${itemPath.join('.')} with value ${JSON.stringify(item)}`)
      }
      return { ...acc, ...validateJson(item, itemPath, validator) }
    }, {})
  } else if (typeof jsonObj === 'object' && jsonObj !== null) {
    return Object.entries(jsonObj).reduce<{ [key: string]: Json }>((acc, [key, value]) => {
      const itemPath = [...path, key]
      if (validator && validator(itemPath, value) === false) {
        throw new Error(`Failed condition at path ${itemPath.join('.')} with value ${JSON.stringify(value)}`)
      }
      return { ...acc, ...validateJson(value, itemPath, validator) }
    }, {})
  } else {
    const key = path.join('.')
    if (validator && validator(path, jsonObj) === false) {
      throw new Error(`Failed condition at path ${key} with value ${JSON.stringify(jsonObj)}`)
    }
    return { [key]: jsonObj }
  }
}

function betterTypeOf(value: any) {
  if (value === null) {
    return 'null'
  }
  if (Array.isArray(value)) {
    return 'array'
  }
  return typeof value
}
