import { Description, Label, Radio, RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'

import { FormInputProps } from '../fields/types'

const logger = debug('app:RadioGroup')

export type RadioGroupInputProps = FormInputProps & {
  hasError?: boolean
  onChange?: (value: any) => void
  options: {
    value: any
    label: string | JSX.Element
    description?: string | JSX.Element
  }[]
  value?: string
}

function RadioGroupInput({ label, description, value, hasError, options, ...rest }: RadioGroupInputProps) {
  // Prevent setting defaultValue before the form is registered
  if (value === undefined) {
    return null
  }

  return (
    <RadioGroup
      {...rest}
      onClick={(e) => {
        // Clicks were triggering form submission
        e.preventDefault()
      }}
      defaultValue={value}
      className={clsx('flex flex-col gap-3', rest.className)}
    >
      {(label || description) && (
        <div>
          {label && (
            <Label className='flex flex-col gap-1'>
              <div className='text-sm font-semibold'>{label}</div>
            </Label>
          )}
          {description && <Description className='text-neutral-8 text-xs'>{description}</Description>}
        </div>
      )}
      <div className={'flex flex-col gap-4'}>
        {options.map((option) => (
          <Radio key={JSON.stringify(option.value)} value={option.value}>
            {({ checked }) => {
              return (
                <div>
                  <Label className={`flex items-center gap-2`}>
                    <div className='mr-2'>
                      {checked ? (
                        <div className='ring-information-10 h-6 w-6 rounded-full ring-8 ring-inset' />
                      ) : (
                        <div
                          className={clsx(
                            'h-6 w-6 rounded-full ring-1 ring-inset',
                            hasError ? 'ring-error-8' : 'ring-information-10',
                          )}
                        />
                      )}
                    </div>
                    <div>
                      <div className='flex items-baseline text-sm font-semibold'>{option.label}</div>
                      {option?.description && (
                        <Description className={'text-neutral-8 text-xs'}>{option.description}</Description>
                      )}
                    </div>
                  </Label>
                </div>
              )
            }}
          </Radio>
        ))}
      </div>
    </RadioGroup>
  )
}

export default RadioGroupInput
