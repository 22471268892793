import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Organization } from '@vendia/management-api-types'
import { useEffect } from 'react'
import useApi from 'src/utils/hooks/use-api'
import { refetchOrg } from 'src/utils/hooks/use-get-org'
import { refetchOrgDetails } from 'src/utils/hooks/use-get-org-details'

import Button from '../buttons/button'
import Loader from '../loaders/page-loader'
import BasicModal from '../modals/modal.basic'
import { logger, ModalState, OrgModalInner } from './org-onboarding-modal'

export function StepWrapUp({
  org,
  setModalState,
  dismissModal,
}: {
  org?: Organization
  setModalState: (state: Partial<ModalState>) => void
  dismissModal: () => void
}) {
  const api = useApi()
  const queryClient = useQueryClient()
  const sumbitCreateOrgMutation = useMutation<any, Error>({
    mutationFn: async () => {
      const res = await api.graphqlFetch({
        query: /* GraphQL */ `
          mutation RequestOrgCreate($domains: [String], $admins: [String]) {
            requestOrgCreate(domains: $domains, admins: $admins)
          }
        `,
        variables: {
          domains: [],
          admins: [],
        },
      })

      logger('requestOrgCreate res is', res)

      // Artifical delay so it's clear that something has happened
      await new Promise((resolve) => setTimeout(resolve, 3000))

      refetchOrg({ queryClient })
      refetchOrgDetails({ queryClient })

      return res
    },
  })

  useEffect(() => {
    sumbitCreateOrgMutation.mutate()
  }, [])

  return (
    <>
      <OrgModalInner>
        <div className='mb-6 flex max-w-4xl flex-col items-center justify-center gap-8 sm:p-6'>
          {sumbitCreateOrgMutation.isPending && (
            <div className='flex flex-col items-center gap-4'>
              <Loader className='!block' />
              <div className='text-lg'>
                Submitting your request to create the <strong>{org?.name}</strong> organization...
              </div>
            </div>
          )}
          {sumbitCreateOrgMutation.isError && (
            <div className='flex flex-col gap-4'>
              <p>
                <strong>
                  Our apologies! Something went wrong while submitting your request to create the {org?.name}{' '}
                  organization.
                </strong>{' '}
              </p>
              <p className='text-red-500'>
                <strong>{sumbitCreateOrgMutation?.error?.message}</strong>
              </p>
              <p>
                Please try again later, or contact{' '}
                <Button kind='link' href='mailto:support@vendia.com' className='!text-base'>
                  support@vendia.com
                </Button>
                .
              </p>
            </div>
          )}
          {sumbitCreateOrgMutation.isSuccess && (
            <div className='flex flex-col gap-4'>
              <p>
                Great! We've begun the process of creating the <strong>{org?.name}</strong> organization.{' '}
                <strong>
                  It can take up to one full business day to approve the requested domain(s) and finish creating your
                  org.
                </strong>{' '}
                We'll send you an email when your Org is ready!
              </p>
              <p>
                In the meantime, if you've been invited to join another Org's Uni, you'll be able to accept the invite
                and begin collaborating <em>immediately</em>.
              </p>
            </div>
          )}
        </div>
      </OrgModalInner>
      <BasicModal.Footer>
        <Button key='create' kind='primary' className='w-40' onClick={dismissModal}>
          Close
        </Button>
      </BasicModal.Footer>
    </>
  )
}
