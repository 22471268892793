import debug from 'debug'
import Button from 'src/components/buttons/button'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import { EditSharingPermissions } from 'src/components/lakehouse/edit-sharing-permissions'
import { LakehouseEditStepValues } from 'src/types/lakehouse'

import { StepId } from './config'

const logger = debug('app:sharingPermissions')

export const StepSharingPermissionsEdit: StepComponent<LakehouseEditStepValues> = (props) => {
  const { submitStep } = props

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        centered
        large
        title='Configure sharing permissions'
        description={
          'Specify the columns that will be included in this data product along with options for data masking.'
        }
      />
      <ScrollableStepContent inset>
        <div className='w-full max-w-7xl'>
          <div className='flex w-full flex-col gap-4 px-4'>
            <EditSharingPermissions {...props} />
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        {/* <Button
          kind='tertiary'
          className='w-28'
          // TODO: fis this - need to copy the data and discard the changes if they cancel edits
          onClick={() => setCurrStepId(StepId.EditDataProducts)}
          data-testid='back-button'
        >
          Cancel
        </Button> */}
        <Button
          kind='primary'
          onClick={() => {
            submitStep({ nextStepId: StepId.DataProducts })
          }}
          data-testid='next-button'
        >
          Update Sharing Permissions
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}
