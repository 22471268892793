import { assert } from 'src/utils/assert'

import {
  Dataset,
  JsonSchema,
  ObjectSchema,
  ScalarType,
  SchemaPath,
  SchemaType,
  VendiaAclsKey,
  VendiaIndexesKey,
  VendiaSchema,
} from '../../../types/schema'

export const createArrayOfObjectsEntityObjectDefinitionPath = (entityKey: string) => {
  return ['properties', entityKey, 'items']
}

export const getParentObjectOfFieldAtPath = ({ schema, path }: { schema: VendiaSchema; path: SchemaPath }) => {
  // Remove field key and "properties"
  const parentObjectPath = path.slice(0, -2)
  return getAtPath({ schema, path: parentObjectPath })
}

export const getObjectAtPath = ({ schema, path }: { schema: VendiaSchema; path: SchemaPath }): ObjectSchema => {
  const object = getAtPath({ schema, path })
  assert(object.type === 'object', 'Expected object type')
  object.properties = object.properties || {}
  object.required = object.required || []
  return object as ObjectSchema
}

export const getAtPath = ({ schema, path }: { schema: VendiaSchema; path: SchemaPath }): JsonSchema => {
  let definition = schema
  for (const pathSegment of path) {
    definition = definition[pathSegment as keyof typeof definition]
  }
  return definition
}

export const schemaTypeToLabel = (type: SchemaType) => {
  const map = {
    string: 'Text',
    integer: 'Number (whole, e.g. 1, 2, 3)',
    number: 'Number (decimal, e.g. 1.1, 2.12, 3.123)',
    boolean: 'Boolean (e.g. true/false)',
    object: 'Object (Advanced)',
    array: 'List (Advanced)',
  }
  return map[type]
}
export const schemaDefToPillText = (schema: JsonSchema) => {
  const map: Record<string, string> = {
    string: 'Text',
    integer: 'Number (whole)',
    number: 'Number (decimal)',
    boolean: 'Boolean',
    object: 'Object',
    array: 'List',
  }

  const type = schema.type as string
  if (type === 'array') {
    return `List of ${map[schema?.items?.type as string]?.toLowerCase()} items`
  }
  return map[type]
}

export function getTypeFromDatasetValues(dataset: Dataset, key: string): ScalarType {
  const value = dataset[0][key]
  const type = typeof value
  if (type === 'number' && Number.isInteger(value)) {
    return 'integer'
  }
  if (['string', 'number', 'boolean'].includes(type)) {
    return type as ScalarType
  }
  return 'string'
}

// We'll put their provided names into the "title" property and build a key compatible with our system
// Must begin with number and include only letters and numbers. Can use capFirstLetter for entity names.
export function getSchemaKeyFromName({ name, capFirstLetter = false }: { name: string; capFirstLetter?: boolean }) {
  // Strip any non-letter characters from the beginning of the name, needs to begin with letter
  const trimmed = name.trim().replace(/^[^a-zA-Z]+/, '')
  // Split on any non-letter or non-number characters
  const parts = trimmed.split(/[^a-zA-Z0-9]+/)
  // Capitalize the first letter of each part
  const capitalized = parts.map((part, index) => {
    if (index === 0 && !capFirstLetter) return part
    return part.charAt(0).toUpperCase() + part.slice(1)
  })
  // Join the parts back together
  return capitalized.join('')
}

export function getExistingIndexKey({
  schema,
  fieldKey,
  entityKey,
}: {
  schema: VendiaSchema
  fieldKey: string | null
  entityKey: string | null
}) {
  if (!fieldKey || !entityKey) return
  const indexes = schema['x-vendia-indexes'] ?? {}
  return Object.keys(indexes).find((key) => {
    return indexes[key]?.[0]?.property === fieldKey && indexes[key]?.[0]?.type === entityKey
  })
}

// Only top-level (ie, not located within nested objects/arrays) scalar fields on 'array of object' entity types can be indexed
export function canBeIndexedOrUnique(selectedFieldParentPath: SchemaPath | null) {
  if (!selectedFieldParentPath) return false
  // Should be like: ['properties', '<entity key>', 'items']
  return (
    selectedFieldParentPath.length === 3 &&
    selectedFieldParentPath[0] === 'properties' &&
    selectedFieldParentPath[2] === 'items'
  )
}

export function sortVendiaKeysAboveProperties(schema: VendiaSchema) {
  // Sort schema keys so x-vendia-acls and x-vendia-indexes are always above 'properties'
  const sortedSchemaEntries = Object.entries(schema).sort(([a], [b]) => {
    if (a.startsWith('x-vendia') && b === 'properties') {
      return -1
    }
    if (b.startsWith('x-vendia') && a === 'properties') {
      return 1
    }
    return 0
  })
  return Object.fromEntries(sortedSchemaEntries)
}

export function getEmptySchema() {
  const emptySchema: VendiaSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'Vendia Share JSON schema',
    type: 'object',
    [VendiaIndexesKey]: {},
    [VendiaAclsKey]: {},
    properties: {},
  }

  return emptySchema
}
