import { useContext } from 'react'
import Button from 'src/components/buttons/button'
import { VendiaUniqueKey } from 'src/types/schema'

import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'
import { getExistingIndexKey } from './utils'

export function EntityDetailsColumn() {
  const {
    setDesignerState,
    schema,
    selectedEntityKey,
    selectedEntity,
    selectedEntityFieldKeys,
    readOnlyMode,
    dataModelMode,
  } = useContext(SchemaDesignerContext) as SchemaDesignerContextType

  if (schema == null || selectedEntity == null || selectedEntityKey == null) {
    return null
  }

  const indexedFieldKeys = selectedEntityFieldKeys.filter((fieldKey) => {
    return getExistingIndexKey({ schema, entityKey: selectedEntityKey, fieldKey })
  })

  const uniqueKeyArr = selectedEntity?.items?.[VendiaUniqueKey] ?? []
  return (
    <div className='border-neutral-6 ml-6 flex w-1/4 flex-col gap-6 border-l pl-10 pt-6'>
      <div>
        <div className='flex items-center justify-between'>
          <div className='text-xl font-bold'>{schema.properties[selectedEntityKey].title || selectedEntityKey}</div>
          {!readOnlyMode && (
            <Button
              kind='link'
              onClick={() => {
                setDesignerState({ status: Status.SHOW_ENTITY_MODAL_EDIT })
              }}
            >
              Edit
            </Button>
          )}
        </div>
        {selectedEntity.description && <div className='mt-4 text-sm'>{selectedEntity.description}</div>}
      </div>

      <hr />
      <div>
        <div className='mb-2 font-bold'>Unique identifiers</div>
        <div className='flex flex-col'>
          {selectedEntity?.items?.[VendiaUniqueKey]?.map((fieldKey) => (
            <div key={fieldKey}>{selectedEntity[fieldKey as keyof typeof selectedEntity]?.title ?? fieldKey}</div>
          )) ?? <div className='italic'>None</div>}
        </div>
      </div>
      <hr />
      <div>
        <div className='mb-2 font-bold'>Indexed attributes</div>
        <div className='flex flex-col'>
          {indexedFieldKeys.length > 0 ? (
            indexedFieldKeys.map((fieldKey) => (
              <div key={fieldKey}>{selectedEntity[fieldKey as keyof typeof selectedEntity]?.title ?? fieldKey}</div>
            ))
          ) : (
            <div className='italic'>None</div>
          )}
        </div>
      </div>
      <hr />
      <div>
        <div className='mb-2 font-bold'>Number of attributes</div>
        <div>{selectedEntityFieldKeys.length}</div>
      </div>
      {dataModelMode && (
        <>
          <hr />
          <div>
            <Button kind='primary' icon={'pencil'} className='min-w-40' to={'../settings/schema/evolve'}>
              Evolve schema
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
