import clsx from 'clsx'
import { FocusEvent, ForwardedRef, forwardRef, InputHTMLAttributes, ReactElement, RefObject, useState } from 'react'

import { FormInputProps } from '../fields/types'
import { useFocusOnError } from './use-focus-on-error'

export type TextAreaInputProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'form' | 'onChange'> &
  Omit<FormInputProps, 'onBlur'> & {
    onChange?: (v: string) => void
    hasError?: boolean
  }

const TextAreaInput = (
  {
    name,
    className,
    label,
    description,
    disabled,
    placeholder,
    useNestedLabel,
    value,
    hasError,
    onChange,
    onBlur,
    onFocus,
    focusOnError,
    ...rest
  }: TextAreaInputProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) => {
  const [isFocused, setIsFocused] = useState(false)
  useFocusOnError(ref as RefObject<HTMLTextAreaElement>, hasError, focusOnError)

  const inputClasses = clsx(
    className,
    'flex h-[44px] min-h-[44px] w-full flex-1 flex-col rounded-md border border-solid border-black p-3',
    hasError && 'border-2 border-red-500',
    useNestedLabel && !value && 'placeholder:text-gray-500',
    useNestedLabel && value && '!pb-2 !pt-4 font-semibold !text-gray-700',
    !description && 'mt-[5px]',
    isFocused && !hasError && !disabled && '!border-gray-500 !ring-1 !ring-gray-500',
  )

  return (
    <div className='flex w-full'>
      <textarea
        {...rest}
        id={name}
        name={name}
        aria-describedby={`${name}-description`}
        className={clsx(inputClasses, 'w-full, h-[inherit]')}
        disabled={disabled}
        placeholder={useNestedLabel ? (label as string) : placeholder}
        onFocus={(e: FocusEvent<HTMLTextAreaElement, Element>) => {
          setIsFocused(true)
          if (onFocus) onFocus(e)
        }}
        onBlur={(e: FocusEvent<HTMLTextAreaElement, Element>) => {
          setIsFocused(false)
          if (onBlur) onBlur(e)
        }}
        onChange={(e) => {
          if (onChange) onChange(e.target.value)
        }}
        value={value}
        ref={ref}
      />
    </div>
  )
}

export default forwardRef<HTMLTextAreaElement, TextAreaInputProps>(TextAreaInput)
